import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import "../custom.css"; // Make sure your CSS file has the necessary styles

const WidgetColumn = ({ children }) => {
    return (
        <Col md={3}>
            <div className="widget-column">
                <Row>{children}</Row>
            </div>
        </Col>
    );
};

WidgetColumn.propTypes = {
    children: PropTypes.node.isRequired,
};

export default WidgetColumn;
