import React, { useState } from "react";
import CustomNavbar from "../Components/customNavbar";
import SitewideFilter from "../Components/sitewideFilter";
import securityCheck from "../Components/security/SecurityCheck";
import { useLocation } from "react-router-dom";

// Destructure props to directly get children
const Layout = ({
  onClickApply,
  onSortingChange,
  onAvailableGroupingChange,
  onGroupingChange,
  selectedDashboard,
  selectedSort,
  selectedGrouping,
  availableGroupings,
  children,
  filtersEnabled,
}) => {
  const [state, setState] = useState({ page: "Dashboard" });

  const location = useLocation();
  const pathname = location.pathname.toLowerCase();

  const setPage = (pageToSet) => {
    setState({ ...state, page: pageToSet });
  };

  return (
    <>
      <CustomNavbar page={state.page} setPage={setPage} className="h-100" />
      <div className="container-fluid mb-3 px-5" style={{ maxWidth: "1600px" }}>
        {(pathname.includes("/dashboard") || pathname.includes("/reports/") || pathname.includes("/finance/")) &&
          !pathname.includes("/reports/powerbi") && (
            <SitewideFilter
              onSortingChange={onSortingChange}
              onAvailableGroupingChange={onAvailableGroupingChange}
              onGroupingChange={onGroupingChange}
              onClickApply={onClickApply}
              selectedDashboard={selectedDashboard}
              selectedSort={selectedSort}
              selectedGrouping={selectedGrouping}
              availableGroupings={availableGroupings}
              filtersEnabled={filtersEnabled}
            />
          )}
        {children}
      </div>
    </>
  );
};

Layout.addToLog = true; //adding log is not required
Layout.needSecurityCheck = true; //security check is not required
export default securityCheck(Layout);
