import React, { useState, useEffect } from "react";
import {
    Col,
    Row,
    Stack,
    Card,
    OverlayTrigger,
    Popover,
    Button,
    Form,
    ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import "../custom.css"; // Import the CSS for styling
import "../Styles/WatermarkWidget.css";

import { apiRequest } from "../Components/api/Request";
import getCurrentTime from "../Functions/getCurrentTime";

const WatermarkWidget = ({ watermarks, acknowledgement, clinic, warning }) => {
    const [agree, setAgree] = useState(false);
    const [disagree, setDisagree] = useState(false);
    const [reasonText, setReasonText] = useState("");

    const [refreshAcknowledgement, setRefreshAcknowledgement] =
        useState(acknowledgement);
    const [refreshWarning, setRefreshWarning] = useState(warning);

    useEffect(() => {
        if (!disagree) {
            setReasonText("");
        }
    }, [disagree]);

    useEffect(() => {
        if (acknowledgement && acknowledgement.status) {
            setReasonText(acknowledgement.reason || "");
            setDisagree(false);
        }
    }, [acknowledgement]);

    const handleAgree = () => {
        setAgree(true);
        setDisagree(false);
    };

    const handleDisagree = () => {
        setAgree(false);
        setDisagree(true);
    };

    const handleSubmit = async () => {
        const endpoint = "Widget/Acknowledgement";

        const storedUserSettings = localStorage.getItem("amplifi_user_data");

        if (storedUserSettings) {
            const userSettings = JSON.parse(storedUserSettings);
            const userId = userSettings.userId;

            const data = {
                user: userId,
                clinic: clinic,
                status: agree,
                reason: reasonText,
            };

            //await apiRequest(apiUrl, endpoint, method, data);

            const widgetDataRes = await apiRequest(
                process.env.REACT_APP_API_URL,
                endpoint,
                "POST",
                data
            );

            

            if (widgetDataRes.data) {
                setRefreshAcknowledgement({
                    status: agree ? "agreed" : "disagreed",
                    user: userSettings.firstName + " " + userSettings.lastName,
                    reason: data.reason,
                    time: getCurrentTime(),
                });
            } else {
                setRefreshWarning(
                    "Someone has already submitted today's watermark for this clinic, please re-apply your filters"
                );
                warning = "Someone has already submitted today's watermark for this clinic, please re-apply your filters";
            }
        }
    };

    

    const renderPopoverContent = (staffGroups, fillColor) => (
        <>
            {staffGroups.map((group, index) => (
                <div key={index}>
                    <h6>{group.staffName}</h6>
                    {group.slots.map((slot, idx) => (
                        <Row
                            key={idx}
                            style={{ color: slot.isFilled ? "#9BD69D" : "#FF8888" }}
                        >
                            <Col xs={4}>
                                <FontAwesomeIcon
                                    icon={slot.isFilled ? "check" : "xmark"}
                                    style={{ color: slot.isFilled ? "#9BD69D" : "#FF8888" }}
                                />{" "}
                                <strong style={{ color: "black" }}>{slot.slotTime} - </strong>
                            </Col>
                            <Col xs={8}>
                                {slot.isFilled
                                    ? `${slot.patientName || "No name"} - ${slot.appointmentType}`
                                    : "No Appt Scheduled"}
                            </Col>
                        </Row>
                    ))}
                    {index < staffGroups.length - 1 && <hr />}
                </div>
            ))}
        </>
    );

    const renderCard = (day) => {
        const totalSlots = day.staffGroups.reduce(
            (acc, group) => acc + group.slots.length,
            0
        );
        const filledSlots = day.staffGroups.reduce(
            (acc, group) => acc + group.slots.filter((slot) => slot.isFilled).length,
            0
        );
        const fillColor = filledSlots === totalSlots ? "#9BD69D" : "#FF8888";

        const cardStyle = {
            height: 60,
            width: 86,
            boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.25)",
            backgroundColor: fillColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        };

        //cardStyle.width = cardStyle.height;

        const popover = (
            <Popover
                id={`popover - ${day.day}`}
                style={{ position: "absolute", minWidth: 290 }}
            >
                <Popover.Body>
                    {renderPopoverContent(day.staffGroups, fillColor)}
                </Popover.Body>
            </Popover>
        );

        return (
            <div key={"card-" + day.day}>
                <h6>
                    <center>
                        <strong>{day.dayTitle}</strong>
                    </center>
                </h6>
                <OverlayTrigger placement="bottom" overlay={popover} key={day.day}>
                    <Card style={cardStyle} className="text-center">
                        <Card.Body
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            <Card.Text style={{ fontSize: "24px" }}>
                                <strong>
                                    {filledSlots}/{totalSlots}
                                </strong>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </OverlayTrigger>
            </div>
        );
    };

    const renderToday = () => {
        // Check if all days meet their goals (all slots for all staff groups are filled)
        const allDaysMetGoal = watermarks.every((day) =>
            day.staffGroups.every((group) =>
                group.slots.every((slot) => slot.isFilled)
            )
        );

        

        const todayStyle = {
            alignItems: "center",
            fontSize: "1.15rem",
            color: "white",
            backgroundColor: allDaysMetGoal ? "#9BD69D" : "#FF8888", // Green if met, red if failed
            padding: "0px",
            borderRadius: "5px",
        };

        return (
            <>
                <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                        <div style={{ height: "50%" }}>
                            <h5>
                                <center>
                                    <strong>Today</strong>
                                </center>
                            </h5>
                            <Card style={todayStyle} className="text-center">
                                <Card.Body>
                                    <Card.Text>{allDaysMetGoal ? "Pass" : "Fail"}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={8}>
                        <Form.Label htmlFor="disagreementReason">
                            Disagreement Reason
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={reasonText}
                            id="disagreementReason"
                            onChange={(e) => setReasonText(e.target.value)}
                            disabled={
                                !disagree ||
                                (refreshAcknowledgement && refreshAcknowledgement.user)
                            }
                            style={{ height: "65%" }}
                        />
                    </Col>
                </Row>
                <hr style={{ margin: 10 }} />
                <Row>
                    {refreshAcknowledgement && refreshAcknowledgement.user ? (
                        <>
                            <Col md={12}>
                                {refreshAcknowledgement.user} {refreshAcknowledgement.status}{" "}
                                with this status at {refreshAcknowledgement.time}
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col md={8}>
                                <ButtonGroup className="w-100">
                                    <Button
                                        variant="outline-success"
                                        onClick={handleAgree}
                                        active={agree}
                                    >
                                        Agree
                                    </Button>
                                    <Button
                                        variant="outline-danger"
                                        onClick={handleDisagree}
                                        active={disagree}
                                    >
                                        Disagree
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col md={4}>
                                <Button
                                    variant="primary"
                                    disabled={!agree && !(disagree && reasonText)}
                                    className="w-100"
                                    onClick={() => handleSubmit()}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            </>
        );
    };

    return (
        <Row className="align-items-center" style={{height:"100%"}}>
            {/* Ensure the Row fills the container's height and centers children vertically */}
            {!Array.isArray(watermarks) ? (
                <Col md={{ span: 6, offset: 3 }}>
                    <div className="alert alert-warning mb-0 mt-4" role="alert">
                        {warning || "Invalid data provided"}
                    </div>
                </Col>
            ) : (
                <>
                    <Col md={3} className="mb-3">{renderToday()}</Col>
                    <Col md={9}>
                        <div className="w-100 watermark-stack">
                            <Stack direction="horizontal" gap={4} className="p-3">
                                {watermarks.map((day) => renderCard(day))}
                            </Stack>
                        </div>
                    </Col>
                </>
            )}
        </Row>
    );
};

WatermarkWidget.propTypes = {
    watermarks: PropTypes.arrayOf(
        PropTypes.shape({
            dayTitle: PropTypes.string.isRequired,
            day: PropTypes.string.isRequired,
            staffGroups: PropTypes.arrayOf(
                PropTypes.shape({
                    staffName: PropTypes.string.isRequired,
                    slots: PropTypes.arrayOf(
                        PropTypes.shape({
                            slotTime: PropTypes.string.isRequired,
                            patientName: PropTypes.string,
                            appointmentType: PropTypes.string,
                            isFilled: PropTypes.bool.isRequired,
                        })
                    ).isRequired,
                })
            ).isRequired,
        })
    ),
    warning: PropTypes.string,
    clinic: PropTypes.number,
    acknowledgement: PropTypes.shape({
        status: PropTypes.string,
        reason: PropTypes.string,
        user: PropTypes.string,
        time: PropTypes.string,
    }),
};

export default WatermarkWidget;
