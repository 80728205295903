import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, Spinner, Form } from "react-bootstrap";
import { apiRequest } from "./api/Request";
import MultiSelect from "./multiSelect";
import PropTypes from "prop-types";
import "../custom.css";

const UserGroupsEditor = ({ userId, userGroups, updateGroups }) => {
	const [uGroups, setUGroups] = useState([]);
	const [availableGroups, setAvailableGroups] = useState([]);
	const [availablePermissions, setAvailablePermissions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [permissionMap, setPermissionMap] = useState({});

	useEffect(() => {
		const fetchGroups = async () => {
			setIsLoading(true);
			const response = await apiRequest(process.env.REACT_APP_API_URL, "Maintenance/get-groups", "get");
			let groups = response.data.map((group) => ({
				id: group.securityGroupId,
				name: group.groupName,
				disabled: false,
				permissions: JSON.parse(group.permissionIds || "[]"), // Parse permission IDs
			}));

			// Sort groups alphabetically by their 'name' property
			groups.sort((a, b) => a.name.localeCompare(b.name));

			setAvailableGroups(groups);
			setIsLoading(false);
		};
		fetchGroups();
	}, []);

	// Fetch permissions and build permission map
	useEffect(() => {
		const fetchPermissions = async () => {
			setIsLoading(true);
			const response = await apiRequest(process.env.REACT_APP_API_URL, "Maintenance/get-permissions", "get");
			const permissions = response.data.map((permission) => ({
				id: permission.securityPermissionId,
				name: permission.permissionFriendlyName,
				parentId: permission.parentPermissionId,
				groups: JSON.parse(permission.groups || "[]"), // Parse group names
			}));

			// Create a mapping from permission IDs to permission objects
			const permMap = {};
			permissions.forEach((perm) => {
				permMap[perm.id] = perm;
			});

			setAvailablePermissions(permissions);
			setPermissionMap(permMap); // Store the permission map
			setIsLoading(false);
		};
		fetchPermissions();
	}, []);

	// Pre-select user's groups when component loads
	useEffect(() => {
		let initialGroupIds = [];
		if (userGroups != null) {
			if (typeof userGroups === "string") {
				initialGroupIds = JSON.parse(userGroups).map(Number);
			} else if (Array.isArray(userGroups)) {
				initialGroupIds = userGroups.map(Number);
			}
		}
		setUGroups(initialGroupIds);
	}, [userGroups]);

	// Compute user's effective permissions based on selected groups
	const userPermissions = useMemo(() => {
		const perms = new Set();

		uGroups.forEach((groupId) => {
			const group = availableGroups.find((g) => g.id === groupId);
			if (group && group.permissions) {
				group.permissions.forEach((permId) => {
					perms.add(permId);
				});
			}
		});

		return perms;
	}, [uGroups, availableGroups]);

	// Build permission hierarchy
	const permissionHierarchy = useMemo(() => {
		// Build a mapping from parentId to list of child permissions
		const hierarchy = {};
		availablePermissions.forEach((perm) => {
			const parentId = perm.parentId || null; // Use null for top-level permissions
			if (!hierarchy[parentId]) {
				hierarchy[parentId] = [];
			}
			hierarchy[parentId].push(perm);
		});

		// Now, sort the permissions within each parentId
		Object.keys(hierarchy).forEach((parentId) => {
			hierarchy[parentId].sort((a, b) => a.name.localeCompare(b.name));
		});

		return hierarchy;
	}, [availablePermissions]);

	// Render permissions recursively
	const renderPermissions = (parentId = null, level = 0) => {
		const perms = permissionHierarchy[parentId];
		if (!perms) return null;

		return perms.map((perm) => {
			const isGranted = userPermissions.has(perm.id);
			const isInherited = perm.parentId && userPermissions.has(perm.parentId);

			// Get the children of the current permission
			const children = permissionHierarchy[perm.id] || [];

			// Determine if any child permissions are granted
			const isUngrantedParentWithGrantedChildren = !isGranted && children.some((child) => userPermissions.has(child.id));

			const inheritedClass = isGranted || isInherited ? "checkbox-inherited" : isUngrantedParentWithGrantedChildren ? "checkbox-inherited-indeterminate" : "";

			const hasChildren = children.length > 0;

			return (
				<div key={perm.id} style={{ marginLeft: level * 20 + "px" }}>
					<Form.Check type="checkbox" id={`perm-${perm.id}`} label={perm.name} checked={isGranted} disabled={true} className={inheritedClass} />
					{hasChildren && renderPermissions(perm.id, level + 1)}
				</div>
			);
		});
	};

	// Handle group selection
	const handleGroupSelection = (selectedGroupIds) => {
		setUGroups(selectedGroupIds);
		updateGroups(selectedGroupIds);
	};

	return (
		<Row className="w-100">
			<Col>
				<Card className="mx-3">
					<Card.Header>
						<Row>
							<Col>
								<h5>Group Mapping</h5>
							</Col>
						</Row>
					</Card.Header>
					<Card.Body>
						<Row>
							<Col md={6}>
								{isLoading ? (
									<Spinner animation="border" role="status">
										<span className="sr-only">Loading...</span>
									</Spinner>
								) : (
									<MultiSelect
										keepOpen={true}
										selectAllCheck={false}
										options={availableGroups.map((group) => ({
											...group,
											tooltip: `Grants: ${group.permissions.join(", ") || "No Permissions"}`, // Tooltip for granted permissions
										}))}
										onChange={handleGroupSelection}
										selectedOptions={uGroups}
										singularNoun="Group"
										disabled={false}
									/>
								)}
							</Col>
							<Col md={6}>
								<Card className="h-100">
									<Card.Header>Effective Permissions</Card.Header>
									<Card.Body>
										{isLoading ? (
											<Spinner animation="border" role="status">
												<span className="sr-only">Loading...</span>
											</Spinner>
										) : (
											<div className="options-dropdown keepOpen">{renderPermissions()}</div>
										)}
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

UserGroupsEditor.propTypes = {
	userGroups: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.string]),
	updateGroups: PropTypes.func.isRequired,
	userId: PropTypes.number.isRequired,
};

export default UserGroupsEditor;
