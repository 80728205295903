import { apiRequest } from "../api/Request";
export const getSessionData = () => {
	const response = localStorage.getItem("amplifi_user_data");
	var userData = null;
	if (response) {
		userData = JSON.parse(response);
	}
	return userData;
};

export const checkSessionExpiry = () => {
	const loginTime = localStorage.getItem("amplifi_loginTime");
	const currentTime = new Date().getTime();
	if (!loginTime || (loginTime && currentTime - loginTime > process.env.REACT_APP_TIME_OUT_MIN * 60 * 1000)) {
		window.open("/LogOut", "_self");
	}
};

export const addUserLog = async (userId, action) => {
	try {
		let data = {
			userId,
			action,
			filters: sessionStorage.getItem("sitewideFilters"),
		};

		let reqRes = await apiRequest(process.env.REACT_APP_API_URL, "Security/log", "post", data);

		return reqRes;
	} catch (error) {
		console.error("Error adding userLog:", error);
		return null;
	}
};

export const checkUserPermission = async (userId, module) => {
	try {
		let reqRes = await apiRequest(process.env.REACT_APP_API_URL, `Security/checkPermissions?userId=${encodeURIComponent(userId)}&moduleName=${encodeURIComponent(module)}`, "get");
		
		return reqRes;
	} catch (error) {
		console.error("Error Checking Permissions: ", error);
		return null;
	}
};
