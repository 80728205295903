import React, { createContext, useContext, useEffect, useState } from "react";
import { getSessionData, checkSessionExpiry, addUserLog, checkUserPermission } from "./Helper";

import { useLocation } from "react-router-dom";
import SomethingWrong from "../common/SomethingWrong";
import AccessDenied from "../common/AccessDenied";
import Loader from "../loader/Loader";

const SessionContext = createContext();

// Custom hook to access the session context
export const useSession = () => {
	return useContext(SessionContext);
};

const securityCheck = (WrappedComponent) => {
	const SecurityCheck = (props) => {
		const [userSessionData, setUserSessionData] = useState(null);
		const [needSecurityCheck, setNeedSecurityCheck] = useState(null);
		const [readyToRender, setReadyToRender] = useState(false);
		const [redirectToLogin, setRedirectToLogin] = useState(false);
		const [accessDenied, setAccessDenied] = useState(false);

		const location = useLocation();

		useEffect(() => {
			

			setReadyToRender(false);
			if (location.pathname.toLowerCase() === "/logout") {
				setReadyToRender(true);
			} else {
				fetchSessionData();
			}
		}, [location.pathname, WrappedComponent.name]);

		const fetchSessionData = async () => {
			try {
				// get session data from Classic ASP app
				checkSessionExpiry();
				let getSessionDataResponse = getSessionData();
				if (getSessionDataResponse) {
					if (getSessionDataResponse.needLogIn) {
						setRedirectToLogin(true);
					} else {
						let userData = getSessionDataResponse;
						setUserSessionData(userData);
						let addLog = WrappedComponent.addToLog == undefined ? true : WrappedComponent.addToLog;
						if (addLog) {
							await addUserLog(userData.userId, location.pathname);
						}
						let securityCheck = WrappedComponent.needSecurityCheck === undefined ? true : WrappedComponent.needSecurityCheck;

						if (props.securityCheck && (props.securityCheck.check != undefined || props.securityCheck.check != null)) {
							securityCheck = props.securityCheck.check;
						}
						setNeedSecurityCheck(securityCheck);
						if (securityCheck) {
							

							const permissionResponse = await checkUserPermission(userData.userId, location.pathname);
							const hasPermission = permissionResponse.data;
							

							setAccessDenied(!hasPermission);
						}
					}
				}
				setReadyToRender(true);
			} catch (error) {
				console.error("Error fetching user session data:", error);
			}
		};

		if (readyToRender) {
			if (redirectToLogin) {
				// Need to redirect to Login. It's handlled in App.js already
			} else if (accessDenied) {
				window.location = "/AccessDenied";
				return null;
			} else {
				return (
					<SessionContext.Provider value={{ userSessionData }}>
						<WrappedComponent {...props} />
					</SessionContext.Provider>
				);
				//<SomethingWrong />;
			}
		} else {
			return <Loader message="Loading..." />;
		}
	};

	return SecurityCheck;
};

export default securityCheck;
