import React, { useEffect, useState, useCallback } from "react";
import Table from "../Components/common/Table";
import Loader from "../Components/loader/Loader";
import { Row, Col, Button, Form, Card, Tabs, Tab, OverlayTrigger, Popover } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { apiRequest } from "../Components/api/Request";
import { buttonFormat } from "../Components/common/Helper";
import columnMap from "../Components/common/DynamicColumnMap";
import { getSessionData } from "../Components/security/Helper";
import sanitizeFilename from "../Functions/sanitizeFilename";
import DynamicOffcanvas from "../Components/common/DynamicOffcanvas";
import UserRolesEditor from "../Components/userRolesEditor";
import UserStaffsEditor from "../Components/userStaffsEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../custom.css";
import UserGroupsEditor from "../Components/userGroupsEditor";

const Maintenance = () => {
	const [indexDetails, setIndexDetails] = useState(null);
	const [indexTableColumn, setIndexTableColumn] = useState(null);
	const [indexEditConfig, setIndexEditConfig] = useState(null);
	const [offCanvasLayout, setOffCanvasLayout] = useState(null);
	const [processedOffCanvasLayout, setProcessedOffCanvasLayout] = useState(null);
	const [newObjectFormat, setNewObjectFormat] = useState(null);
	const [indexConfig, setIndexConfig] = useState(null);
	const [indexData, setIndexData] = useState(null);
	const [index, setIndex] = useState(null);
	const [isSearching, setIsSearching] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [noMaintenanceIndexFound, setNoMaintenanceIndexFound] = useState(false);
	const [errorFound, setErrorFound] = useState(false);
	const [offCanvasAction, setOffCanvasAction] = useState("");
	const [offCanvasError, setOffcanvasError] = useState("");
	const [offCanvasErrorType, setOffcanvasErrorType] = useState("warning");
	const { indexName } = useParams();
	const [allowPaginationOnTable, setAllowPaginationOnTable] = useState("yes");

	const [currentPage, setCurrentPage] = useState(0);
	const [columnFilters, setColumnFilters] = useState([]);

	const [showOffcanvas, setShowOffcanvas] = useState(false);
	const [editData, setEditData] = useState({});

	const openEditForm = (rowData) => {
		let rowDataCopy = { ...rowData }; // Shallow copy of rowData

		if (rowDataCopy.geoEntitiesPerRole && typeof rowDataCopy.geoEntitiesPerRole === "string") {
			rowDataCopy.geoEntitiesPerRole = JSON.parse(rowDataCopy.geoEntitiesPerRole);
		}
		if (rowDataCopy.geoEntitiesPerRole === null) {
			rowDataCopy.geoEntitiesPerRole = [];
		}

		if (rowDataCopy.staffIds && typeof rowDataCopy.staffIds === "string") {
			rowDataCopy.staffIds = JSON.parse(rowDataCopy.staffIds);
		}
		if (rowDataCopy.staffIds === null) {
			rowDataCopy.staffIds = [];
		}

		if (rowDataCopy.groupIds && typeof rowDataCopy.groupIds === "string") {
			rowDataCopy.groupIds = JSON.parse(rowDataCopy.groupIds);
		}
		if (rowDataCopy.groupIds === null) {
			rowDataCopy.groupIds = [];
		}

		setEditData(rowDataCopy);
		setShowOffcanvas(true);
	};

	useEffect(() => {
		//KEEPING THIS COMMENTED CONSOLE LOG TO HAVE IT IF NEEDED
		//console.log(editData);
	}, [editData]);

	useEffect(() => {
		getMaintenanceDetails();
	}, []);

	useEffect(() => {
		if (indexDetails) {
			getIndexData();
		}
	}, [indexDetails]);

	let getMaintenanceDetails = async () => {
		setIsSearching(true);
		let response = await apiRequest(process.env.REACT_APP_API_URL, `Maintenance?urlName=${indexName}`, "get");

		if (response.status === 200) {
			setIndexDetails(response.data);

			let parsedConfigData = JSON.parse(response.data.config);

			let tableColumns = parsedConfigData.tableColumns;

			const updatedTableColumns = columnMap(tableColumns, {
				includeConditionalRendering: true,
				isEmpty,
				buttonFormattorCell: customButtonFormattorCell,
				// Pass any custom formatter functions if needed
			});

			const editConfig = parsedConfigData.editConfig;

			const indexInfo = parsedConfigData.indexConfig[0];

			const offCanvasLayoutConfig = parsedConfigData.offCanvasLayout;

			const processedOffCanvasLayout = processOffCanvasLayout(offCanvasLayoutConfig);

			const newObjectFormatConfig = parsedConfigData.newObjectFormat;

			setIndexEditConfig(editConfig);
			setIndexConfig(indexInfo);
			setOffCanvasLayout(offCanvasLayoutConfig);
			setProcessedOffCanvasLayout(processedOffCanvasLayout);
			setNewObjectFormat(newObjectFormatConfig);

			setIndexTableColumn({ index_column: updatedTableColumns });

			setAllowPaginationOnTable(parsedConfigData.allowPagination ? parsedConfigData.allowPagination : "yes");
		} else if (response.status === 204) {
			setNoMaintenanceIndexFound(true);
			setIsSearching(false);
		} else {
			console.error(response);
			setIsSearching(false);
		}
	};

	const processOffCanvasLayout = (layoutArray) => {
		const processedLayout = [];
		const tabGroups = {};

		layoutArray.forEach((layout) => {
			if (layout.componentLayout === "tabGroup") {
				// Create a new tabGroup entry
				tabGroups[layout.tabGroupName] = {
					...layout,
					components: [],
				};
				// Add the tabGroup to the processedLayout
				processedLayout.push(tabGroups[layout.tabGroupName]);
			} else if (layout.underTabGroup) {
				// Add component to the respective tabGroup
				const tabGroup = tabGroups[layout.underTabGroup];
				if (tabGroup) {
					tabGroup.components.push(layout);
				} else {
					// Handle the case where the tabGroup is not defined yet
					// Create the tabGroup if needed
					tabGroups[layout.underTabGroup] = {
						componentLayout: "tabGroup",
						tabGroupName: layout.underTabGroup,
						components: [layout],
					};
					// Add the tabGroup to the processedLayout
					processedLayout.push(tabGroups[layout.underTabGroup]);
				}
			} else {
				// Regular component, add to processedLayout
				processedLayout.push(layout);
			}
		});

		return processedLayout;
	};

	const renderLayoutComponent = (layout, asTabbedContent) => {
		switch (layout.componentLayout) {
			case "useEditConfig":
				return (
					<Row className="w-100" key={"offcanvas_" + layout.componentLayout}>
						<Col>
							<Card className="p-0 m-3">
								<Card.Header>
									<h5>{indexConfig.singularObject} Info</h5>
								</Card.Header>
								<Card.Body>
									<Row>
										{indexEditConfig &&
											indexEditConfig.map((field) => {
												switch (field.fieldType) {
													case "checkbox":
														return (
															<Col xs={{ span: field.span }} key={field.parameter}>
																<Form.Group className="mb-3 float-start">
																	<Form.Check
																		type={"checkbox"}
																		label={<span>{field.label}</span>}
																		className="text-center mt-4 pt-2"
																		name={field.parameter}
																		id={field.parameter}
																		onChange={(e) =>
																			setEditData((prev) => ({
																				...prev,
																				[field.parameter]: e.target.checked,
																			}))
																		}
																		checked={editData[field.parameter] ?? false}
																	/>
																</Form.Group>
															</Col>
														);
													case "freeText":
														return (
															<Col xs={{ span: field.span }} key={field.parameter}>
																<Form.Group className="mb-3">
																	<Form.Label>{field.label}</Form.Label>
																	<Form.Control
																		type="text"
																		name={field.parameter}
																		onChange={(e) =>
																			setEditData((prev) => ({
																				...prev,
																				[field.parameter]: e.target.value,
																			}))
																		}
																		required={field.required}
																		value={editData[field.parameter] || ""}
																		className={field.required && field.required === true && isEmpty(editData[field.parameter]) ? "unfilledRequired" : ""}
																	/>
																</Form.Group>
															</Col>
														);
													default:
														return null;
												}
											})}
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				);
			case "userRoles":
				return <UserRolesEditor userRoles={editData.geoEntitiesPerRole} updateRoles={updateGeoEntitiesPerRole} asTabbedContent={asTabbedContent} />;
			case "staffMapping":
				return <UserStaffsEditor userId={editData.userId} userStaffs={editData.staffIds} updateStaffs={updateStaffMapping} asTabbedContent={asTabbedContent} />;
			case "permissionsGroups":
				return <UserGroupsEditor userId={editData.userId} userGroups={editData.groupIds} updateGroups={updateGroupMapping} asTabbedContent={asTabbedContent} />;
			default:
				return null;
		}
	};

	const customButtonFormattorCell = ({ value, row, column }) => {
		const handleClick = () => {
			openEditForm(row.original);
			setOffCanvasAction("Edit ");
		};
		return buttonFormat(value, column.buttonFormattor, handleClick);
	};

	const openNewObjectOnForm = (format) => {
		let newObject = {};

		format.forEach((field) => {
			let defaultValue;
			switch (field.fieldType) {
				case "text":
					defaultValue = ""; // Default for text
					break;
				case "number":
					defaultValue = 0; // Default for number
					break;
				case "bool":
					defaultValue = false; // Default for checkboxes
					break;
				case "array":
					defaultValue = []; //Default for arrays
					break;
				default:
					defaultValue = null; // Default for any unspecified types
			}
			newObject[field.fieldName] = field.defaultValue ?? defaultValue;
		});

		setOffCanvasAction("Add ");

		openEditForm(newObject);
	};

	const isEmpty = (value) => {
		return value === null || value === undefined || value === "";
	};

	let callIndexEndpoint = async (endPointConfig, filterData, filterDataConfig) => {
		let data = {};

		if (filterDataConfig) {
			filterDataConfig.foreach((config) => {
				let value = config.value ? config.value : config.accessor ? filterData[config.accessor] : "";
				data[config.name] = value;
			});
		} else {
			data = filterData;
		}

		let reqRes = await apiRequest(process.env.REACT_APP_API_URL, endPointConfig.endPoint, endPointConfig.type ? endPointConfig.type : "get", data);
		return reqRes;
	};

	let getIndexData = async () => {
		let endPointConfig = JSON.parse(indexDetails.endPointConfig);

		setIsSearching(true);
		if (endPointConfig.endPoint) {
			let response = await callIndexEndpoint(endPointConfig);

			if (response.status === 200) {
				setIndexData({ index_data: response.data });
				setIsSearching(false);
			} else {
				console.error(response);
				setIsSearching(false);
			}
		} else {
			setErrorFound(true);
			setIsSearching(false);
		}
	};

	useEffect(() => {
		if (indexData && indexTableColumn && indexDetails) {
			let endPointConfig = JSON.parse(indexDetails.endPointConfig);
			let config = { index_config: endPointConfig };

			const combinedReport = Object.keys(indexData).map((key) => {
				const reportKey = key.replace("_data", ""); // Remove 'Data' to match the column key
				return {
					data: indexData[key],
					column: indexTableColumn[`${reportKey}_column`],
					title: config[`${reportKey}_config`].title,
					fileName: sanitizeFilename(
						config[`${reportKey}_config`].fileName ? config[`${reportKey}_config`].fileName : config[`${reportKey}_config`].title ? config[`${reportKey}_config`].title : indexDetails.name
					),
				};
			});
			setIndex(combinedReport);
		}
	}, [indexData, indexTableColumn, indexDetails]);

	const updateGeoEntitiesPerRole = (updatedRoles) => {
		setEditData((prevData) => ({
			...prevData,
			geoEntitiesPerRole: updatedRoles,
		}));
	};

	const updateStaffMapping = (updatedStaffs) => {
		setEditData((prevData) => ({
			...prevData,
			staffIds: updatedStaffs,
		}));
	};

	const updateGroupMapping = (updatedGroups) => {
		setEditData((prevData) => ({
			...prevData,
			groupIds: updatedGroups,
		}));
	};

	const submitEditedObject = async () => {
		try {
			setIsSaving(true);

			let unfilledRequiredFields = false;

			// Check to make sure that any required fields are filled in
			if (newObjectFormat) {
				newObjectFormat.forEach((field) => {
					if (field.required && isEmpty(editData[field.fieldName])) {
						unfilledRequiredFields = true;
					}
				});
			}

			if (unfilledRequiredFields) {
				setOffcanvasError("Please fill in all required fields");
				setOffcanvasErrorType("danger");
				return; // Early return to stop execution if there are unfilled fields
			}

			let userData = getSessionData();
			editData.editingUserId = userData.userId; //currentUserId;

			// Constructing data for submission
			let data = {
				...editData,
				geoEntitiesPerRole: editData.geoEntitiesPerRole.map((role) => ({
					...role,
					isDefaultRole: role.isDefaultRole === true, // Convert numeric to boolean
				})),
				staffIds: editData.staffIds,
				groupIds: editData.groupIds,
			};

			let saveResponse = await apiRequest(process.env.REACT_APP_API_URL, "Maintenance" + indexConfig.saveEndpoint, "POST", data);

			if (saveResponse.status === 200) {
				const responseData = await saveResponse.data; // Ensure correct parsing of JSON response

				// Handle a successful response

				getMaintenanceDetails();
				setShowOffcanvas(false); // Close the offcanvas on success
			} else {
				const responseData = await saveResponse.data; // Parsing response even in case of error
				console.error("Failed to save:", responseData);
				// Optionally update the UI here to reflect the error
			}
		} catch (error) {
			// Handle network errors or other unexpected errors
			console.error("Error saving changes:", error);
			// Optionally update the UI here to reflect the error
		} finally {
			setIsSaving(false);
		}
	};

	const handleCloseOffCanvas = () => {
		setShowOffcanvas(false);
		setOffCanvasAction("");
		setOffcanvasError("");
	};

	const updateColumnFilters = (id, value) => {
		setColumnFilters((prevFilters) => {
			// Check if filter with the same id already exists
			const existingFilterIndex = prevFilters.findIndex((filter) => filter.id === id);

			// Create a new array based on previous filters
			let newFilters;

			if (existingFilterIndex >= 0) {
				// If value is empty, remove the filter
				if (value === "" || value === null || value === undefined) {
					newFilters = prevFilters.filter((filter) => filter.id !== id);
				} else {
					// Update existing filter's value
					newFilters = prevFilters.map((filter, index) => (index === existingFilterIndex ? { id, value } : filter));
				}
			} else {
				// Add new filter if value is not empty
				if (value !== "" && value !== null && value !== undefined) {
					newFilters = [...prevFilters, { id, value }];
				} else {
					// If value is empty and filter doesn't exist, keep previous filters
					newFilters = prevFilters;
				}
			}

			return newFilters;
		});
	};

	return (
		<>
			{isSearching && <Loader message="Loading..." />}
			{isSaving && <Loader message="Saving..." />}
			{noMaintenanceIndexFound ? (
				<h2 className="text-center">No Index Found</h2>
			) : errorFound ? (
				<h2 className="text-center">Error Found in Index Config</h2>
			) : (
				indexDetails && (
					<div className="px-2" style={{ height: `calc(100vh - 155px)` }}>
						<div>
							<Row>
								<Col xs={{ span: 6 }}>
									<h4 className="text-capitalize mb-0">{indexDetails.name}</h4>
								</Col>
								<Col xs={{ span: 6 }}>
									{newObjectFormat ? (
										<Button variant="outline-primary" className="float-end" onClick={() => openNewObjectOnForm(newObjectFormat)}>
											<FontAwesomeIcon icon="plus" />
											{" Add"}
										</Button>
									) : (
										""
									)}
								</Col>
							</Row>
						</div>
						{index &&
							index.map((maint, i) => {
								return (
									<React.Fragment key={i}>
										{maint.title && <h6 className="text-capitalize fw-bold">{maint.title}</h6>}
										<Table
											pageIndexValue={currentPage}
											updatePageIndexValue={setCurrentPage}
											columnFilter={columnFilters}
											updateColumnFilter={updateColumnFilters}
											columns={maint.column}
											data={maint.data}
											reportTitle={maint.title}
											fileName={index[0].fileName}
											allowPagination={allowPaginationOnTable}
											manualSortBy
										/>
									</React.Fragment>
								);
							})}
					</div>
				)
			)}
			{indexConfig ? (
				<DynamicOffcanvas
					show={showOffcanvas}
					onHide={handleCloseOffCanvas}
					title={offCanvasAction + indexConfig.singularObject}
					errorMessage={offCanvasError}
					errorType={offCanvasErrorType}
					width={indexConfig.offCanvasWidth}
					footerButtons={
						indexEditConfig && (
							<>
								<Button variant="danger" className="me-2" onClick={handleCloseOffCanvas}>
									<FontAwesomeIcon icon="xmark" size="lg" /> Cancel
								</Button>
								<Button variant="primary" onClick={submitEditedObject}>
									<FontAwesomeIcon icon="floppy-disk" /> Save
								</Button>
							</>
						)
					}>
					<div className="offcanvas-content">
						{processedOffCanvasLayout &&
							processedOffCanvasLayout.map((layout) => {
								if (layout.componentLayout === "tabGroup") {
									return (
										<Row key={"Key" + layout.componentName}>
											<Col>
												<Tabs className="mx-3" variant="tabs" defaultActiveKey={layout.components[0].componentName} id={layout.tabGroupName}>
													{layout.components.map((tabComponent) => {
														// Create the popover content if tabDescription exists
														const popover = tabComponent.tabDescription && (
															<Popover id="tab-description-popover" style={{ position: "absolute", zIndex: 2000 }}>
																<Popover.Body>{tabComponent.tabDescription}</Popover.Body>
															</Popover>
														);

														// Wrap the tab title with OverlayTrigger if tabDescription exists
														const tabTitle = tabComponent.tabDescription ? (
															<span style={{ display: "flex", alignItems: "center" }}>
																{tabComponent.componentName}
																<OverlayTrigger placement="bottom" overlay={popover} container={document.body}>
																	<FontAwesomeIcon icon="info-circle" size="sm" className="ms-1 info-icon" style={{ color: "#666" }} />
																</OverlayTrigger>
															</span>
														) : (
															tabComponent.componentName
														);

														return (
															<Tab eventKey={tabComponent.componentName} title={tabTitle} key={tabComponent.componentName}>
																{renderLayoutComponent(tabComponent, true)}
															</Tab>
														);
													})}
												</Tabs>
											</Col>
										</Row>
									);
								} else {
									return renderLayoutComponent(layout);
								}
							})}
					</div>
				</DynamicOffcanvas>
			) : (
				<></>
			)}
		</>
	);
};

export default Maintenance;
