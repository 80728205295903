import React from "react";
import ReactDOM from 'react-dom/client';
import App from './Pages/App';
import './index.css';
import './custom.css';
import './Imports/iconLibrary'; // Import for All Pro Solid FontAwesome Icons
import "@fortawesome/fontawesome-free/css/all.css";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.createRoot(document.getElementById('root')).render(
    /*<React.StrictMode>*/
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    /*</React.StrictMode>*/
);
