import React, { useEffect, useState } from "react";
import moment from "moment";
import Loader from "../Components/loader/Loader";
import { Row, Col, Button, Form, Tooltip, OverlayTrigger, Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { apiRequest } from "../Components/api/Request";
import { amountFormat, percentageFormat, buttonFormat, booleanFormat, dateFormat, linkFormat, updateFileName } from "../Components/common/Helper";
import { getSessionData } from "../Components/security/Helper";
import columnMap from "../Components/common/DynamicColumnMap";
import { isEmpty } from "../Components/common/Helper";
import sanitizeFilename from "../Functions/sanitizeFilename";
import DynamicOffcanvas from "../Components/common/DynamicOffcanvas";
import Table from "../Components/common/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../custom.css";

const Finance = ({ filterOptions }) => {
	const [indexDetails, setIndexDetails] = useState(null);
	const [indexConfig, setIndexConfig] = useState(null);
	const [indexData, setIndexData] = useState(null);
	const [index, setIndex] = useState(null);
	const [noFinanceIndexFound, setNoFinanceIndexFound] = useState(false);
	const [errorFound, setErrorFound] = useState(false);
	const { indexName } = useParams();
	const [allowPaginationOnTable, setAllowPaginationOnTable] = useState("yes");

	const [currentPage, setCurrentPage] = useState(0);
	const [columnFilters, setColumnFilters] = useState([]);

	const [showOffcanvas, setShowOffcanvas] = useState(false);
	const [offCanvasTitle, setOffCanvasTitle] = useState("");
	const [offCanvasError, setOffCanvasError] = useState("");
	const [offCanvasErrorType, setOffCanvasErrorType] = useState("warning");
	const [detailData, setDetailData] = useState(null);
	const [detailColumns, setDetailColumns] = useState([]);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [viewingYear, setViewingYear] = useState(null);
	const [selectedUserRole, setSelectedUserRole] = useState(null);
	const [selectedEndpointConfig, setSelectedEndpointConfig] = useState(null);
	const [selectedClinicId, setSelectedClinicId] = useState(null);
	const [dwStaffs, setDwStaffs] = useState(null);
	const [showingRevenueDetails, setShowingRevenueDetails] = useState(false);
	

	const [isFetchingConfig, setIsFetchingConfig] = useState(false);
	const [isFetchingData, setIsFetchingData] = useState(false);
	const [isFetchingDetail, setIsFetchingDetail] = useState(false);
	const [fileNameExtender, setFileNameExtender] = useState(null);

	const [editedValues, setEditedValues] = useState({});
	const [editReasons, setEditReasons] = useState({});

	// Fetch configuration on mount
	useEffect(() => {
		getFinanceDetails();
	}, []);

	// Fetch data when configuration and filters are available
	useEffect(() => {
		if (indexConfig && filterOptions) {
			getIndexData();
		}
	}, [indexConfig, filterOptions]);

	// Process index data when it changes
	useEffect(() => {
		if (indexData && indexConfig) {
			processIndexData();
		}
	}, [indexData, indexConfig]);

	// useEffect(() => {
	// 	let timer;
	// 	if (offCanvasError) {
	// 		timer = setTimeout(() => {
	// 			setOffCanvasError("");
	// 		}, 5000);
	// 	}
	// 	return () => {
	// 		if (timer) {
	// 			clearTimeout(timer);
	// 		}
	// 	};
	// }, [offCanvasError]);

	// Fetch finance configuration
	const getFinanceDetails = async () => {
		setIsFetchingConfig(true);
		try {
			const response = await apiRequest(process.env.REACT_APP_API_URL, `Finance?urlName=${indexName}`, "get");
			if (response.status === 200) {
				const { data } = response;
				const parsedConfigData = JSON.parse(data.config);
				setIndexConfig(parsedConfigData);
				setIndexDetails(data);

				setAllowPaginationOnTable(parsedConfigData.allowPagination ? parsedConfigData.allowPagination : "yes");
			} else if (response.status === 204) {
				setNoFinanceIndexFound(true);
			} else {
				setErrorFound(true);
			}
		} catch (error) {
			console.error(error);
			setErrorFound(true);
		} finally {
			setIsFetchingConfig(false);
		}
	};

	// Fetch main index data
	const getIndexData = async () => {
		setIsFetchingData(true);
		try {
			const endPointConfig = JSON.parse(indexDetails.endPointConfig);
			if (endPointConfig.endPoint) {
				let response = await callIndexEndpoint(endPointConfig, {}, endPointConfig.filterDataConfig);

				if (response.status == 200) {
					setIndexData({ index_data: response.data });
				} else {
					console.error(response);
				}
			} else if (endPointConfig.displayMultipleReports) {
				let endPointIndexData = {};
				await Promise.all(
					endPointConfig.endPoints.map(async (endPoint) => {
						let endPointReqRes = await callIndexEndpoint(endPoint, {}, endPoint.filterDataConfig);
						if (endPointReqRes.status == 200) {
							let endPointData = endPointReqRes.data;
							if (endPointData) {
								endPointIndexData[endPoint.name + "_data"] = endPointData;
							}
						}
					})
				);
				setIndexData(endPointIndexData);
			}
		} catch (error) {
			console.error(error);
			setErrorFound(true);
		} finally {
			setIsFetchingData(false);
		}
	};

	// Process index data and set up columns
	const processIndexData = () => {
		const endPointConfig = JSON.parse(indexDetails.endPointConfig);
		let config = {};
		if (endPointConfig.displayMultipleReports) {
			endPointConfig.endPoints.map((item) => {
				const key = item.name;
				config[key + "_config"] = item;
			});
		} else {
			config = { index_config: endPointConfig };
		}

		
		const updatedTableColumns = (tableColumns, reportType) =>
			columnMap(tableColumns, {
				includeConditionalRendering: true,
				buttonFormattorCell: customButtonFormattorCell,
				includeExpander: reportType === "employeehcclt" || reportType === "clinic",
				includeExpander: reportType === "employeehcclt" || reportType === "clinic",
			});

		const combinedReport = Object.keys(indexData).map((key) => {
			const reportKey = key.replace("_data", ""); // Remove 'Data' to match the column key
			let tableColumnsFromConfig = indexConfig.tableColumns;
			if (indexConfig.tabs) {
				tableColumnsFromConfig = indexConfig.tabs[reportKey]?.tableColumns;
			}
			const data = indexData[key];
			let processedData = data;

			//Only process data for reports that require nesting
			if (reportKey === "employeehcclt" || reportKey === "clinic") {
				processedData = processDataWithSplits(data);
			}

			return {
				data: processedData, //employeehcp reports will remain the plain indexData[Key] this used to be
				column: updatedTableColumns(tableColumnsFromConfig, reportKey),
				data: processedData, //employeehcp reports will remain the plain indexData[Key] this used to be
				column: updatedTableColumns(tableColumnsFromConfig, reportKey),
				title: config[`${reportKey}_config`]?.title,
				fileName: sanitizeFilename(
					config[`${reportKey}_config`].fileName ? config[`${reportKey}_config`].fileName : config[`${reportKey}_config`].name ? config[`${reportKey}_config`].name : indexDetails.name
				),
				report: config[`${reportKey}_config`].name,
				expandedByDefault: config[`${reportKey}_config`].expandedByDefault ?? false,
			};
		});

		

		setIndex(combinedReport);
	};

	const processDataWithSplits = (data) => {
		const parentRows = [];
		const parentRowMap = {};

		// First pass: Process and store all parent records
		data.forEach((item) => {
			if (item.amplifiRole === "HCC" || item.amplifiRole === "Lab Tech" || item.amplifiRole === "Clinic Total") {
				// This is a parent record
				item.subRows = [];
				parentRows.push(item);
				parentRowMap[item.sipCalculationId] = item; // Use a unique identifier
			}
		});

		// Second pass: Process and associate split records
		data.forEach((item) => {
			if (item.isSplitRecord) {
				// This is a split record
				const parentId = item.parentSipCalculationId; // Ensure your data includes this field

				if (parentRows.find((pr) => pr.sipCalculationId === parentId)) {
					parentRowMap[parentId].subRows.push(item);
				} else {
					// Handle cases where parent is not found
					console.warn(`Parent with ID ${parentId} not found for split record.`);
				}
			}
		});

		return parentRows;
	};

	const formatCellValue = (value, formatters) => {
		if (formatters.amountFormattor) {
			return amountFormat(value, formatters.amountFormattor);
		} else if (formatters.percentageFormattor) {
			return percentageFormat(value, formatters.percentageFormattor);
		} else {
			return value !== null && value !== undefined ? value : "";
		}
	};

	const customButtonFormattorCell = ({ value, row, column }) => {
		// First, format the value using any other formatter
		let formattedValue = value;

		if (column.amountFormattor) {
			formattedValue = amountFormat(value, column.amountFormattor);
		} else if (column.percentageFormattor) {
			formattedValue = percentageFormat(value, column.percentageFormattor);
		} else if (column.booleanFormattor) {
			formattedValue = booleanFormat(value, column.booleanFormattor);
		} else if (column.dateFormattor) {
			formattedValue = dateFormat(value, column.dateFormattor);
		} else if (column.linkFormattor) {
			formattedValue = linkFormat(value, row.values.source, column.linkFormattor);
		}

		if (row.depth > 0) {
			return <div style={{ marginLeft: `${row.depth * 20}px` }}>{formattedValue}</div>;
		}

		const handleClick = () => {
			if (column.buttonFormattor.buttonAction === "viewDetails") {
				const userId = row.original[column.buttonFormattor.buttonAccessor] ?? value;
				const userName = row.original.recipientName ?? row.original.clinicName ?? null;
				const fiscalYear = row.original.fiscalYear ?? null;
				const endpointConfig = column.buttonFormattor.buttonEndpoint;
				const role = row.original.amplifiRole ?? "";

				openDetailOffcanvas(userId, fiscalYear, userName, role, endpointConfig);
			} else if (column.buttonFormattor.buttonAction === "viewRevenueDetails") {
				const userId = row.original["amplifiUserId"] ?? null;
				const clinicId = row.original["dwClinicKey"] ?? null;
				const dwStaffs = row.original["dwStaffs"] ?? null;
				const userName = row.original.recipientName ?? row.original.clinicName ?? null;
				const endpointConfig = column.buttonFormattor.buttonEndpoint;
				const isFiscalYearToDate = column.buttonFormattor.isYearToDate ?? false;
				const role = row.original.amplifiRole ?? "";

				openRevenueDetailOffcanvas(userId, clinicId, dwStaffs, isFiscalYearToDate, role, endpointConfig, userName);
			}
		};
		return buttonFormat(formattedValue, column.buttonFormattor, handleClick);
	};

	const openRevenueDetailOffcanvas = (userId, clinicId, dwStaffs, isFiscalYearToDate, role, endpointConfig, userName) => {
		setSelectedUserId(userId);
		setSelectedClinicId(clinicId);
		setDwStaffs(dwStaffs);
		setOffCanvasTitle(`${isFiscalYearToDate ? "Fiscal Year" : "Month"} Revenue Details for ${userName}`);
		setSelectedUserRole(role);
		setSelectedEndpointConfig(endpointConfig);
		fetchRevenueDetailData(userId, clinicId, dwStaffs, isFiscalYearToDate, endpointConfig, role);
		setShowOffcanvas(true);
	};

	const fetchRevenueDetailData = async (userId, clinicId, dwStaffs, isFiscalYearToDate, endpointConfig, role) => {
		setIsFetchingDetail(true);
		//setIsFetchingData(true);
		setDetailData(null);
		setDetailColumns([]);
		setOffCanvasError("");
		setShowingRevenueDetails(true);

		try {
			// Fetch the report configuration for 'revenue'
			const responseConfig = await apiRequest(process.env.REACT_APP_API_URL, `Reports?urlName=revenue`, "get");

			if (responseConfig.status === 200) {
				const reportDetails = responseConfig.data;
				const parsedConfigData = JSON.parse(reportDetails.config);

				// Process the configuration to set up columns
				let tableColumns;
				if (parsedConfigData.displayMultipleReports) {
					// Handle multiple reports if necessary
				} else {
					let columns = parsedConfigData.tableColumns;
					tableColumns = columnMap(columns, {
						includeConditionalRendering: true,
						isEmpty,
						// Pass any other custom formatting here
					});
				}

				// Build filterData
				let filterData = {};
				// Get date filters from sessionStorage
				const sitewideFilters = JSON.parse(sessionStorage.getItem("sitewideFilters")) || {};
				const fiscalYearStart = moment()
					.month(3)
					.date(1)
					.startOf("day")
					.subtract(moment().month() < 3 ? 1 : 0, "year");

				const startDate = isFiscalYearToDate ? fiscalYearStart.format("YYYY-MM-DD") : moment(sitewideFilters.startDate).format("YYYY-MM-DD");
				const endDate = moment(sitewideFilters.endDate).format("YYYY-MM-DD");

				filterData.startDate = startDate;
				filterData.endDate = endDate;
				filterData.isIndividualReport = true;

				// Determine whether to include all clinics and one provider, or all providers and one clinic
				if (userId && !clinicId) {
					// Employee tab: include all clinics and the selected provider(s)
					filterData.providers = dwStaffs ? dwStaffs.split(",").map((id) => parseInt(id.trim())) : [];
					filterData.clinics = null;
				} else if (clinicId && !userId) {
					// Clinic tab: include all providers and the selected clinic
					filterData.clinics = [clinicId];
					filterData.providers = null;
				}

				// Fetch the data
				let endPointConfig = JSON.parse(reportDetails.endPointConfig);

				// Fetch the data using the endpoint configuration
				let response = await callIndexEndpoint(endPointConfig, filterData, endPointConfig.filterDataConfig);

				if (response.status === 200) {
					const fetchedData = response.data;
					setDetailColumns(tableColumns);
					setDetailData(fetchedData);
				} else {
					setOffCanvasError("Failed to load revenue data.");
					console.error(response);
				}
			} else {
				setOffCanvasError("Failed to load revenue report configuration.");
				console.error(responseConfig);
			}
		} catch (error) {
			setOffCanvasError("Failed to load revenue data.");
			console.error(error);
		} finally {
			setIsFetchingDetail(false);
			//setIsFetchingData(false);
		}
	};

	const EditableCell = ({ value, originalValue, requiresReason, rowKey, monthNumber, onValueChange, existingReason, onReasonChange }) => {
		const [inputValue, setInputValue] = useState(value ?? "");
		const [reason, setReason] = useState(existingReason ?? "");

		// Determine if the value has changed from the original
		const hasValueChanged = inputValue !== originalValue;

		// Determine if a new reason has been provided
		const isNewReasonProvided = reason.trim() !== "" && reason !== existingReason;

		// Apply the special class if value changed and no new reason provided
		const shouldApplyClass = requiresReason && hasValueChanged && !isNewReasonProvided;

		const reasonClassName = shouldApplyClass ? "unfilledRequired" : "";

		const handleInputChange = (e) => {
			const newValue = e.target.value;
			setInputValue(newValue);
			onValueChange(rowKey, monthNumber, newValue);
		};

		const handleReasonInputChange = (e) => {
			const newReason = e.target.value;
			setReason(newReason);
			onReasonChange(rowKey, monthNumber, newReason);
		};

		return (
			<Form.Group className="mb-0">
				<Form.Control type="number" name={rowKey} onChange={handleInputChange} value={inputValue} />
				<Form.Control type="text" name={`${rowKey}-reason`} placeholder="Reason for change" onChange={handleReasonInputChange} value={reason} className={reasonClassName} />
			</Form.Group>
		);
	};

	const handleValueChange = (rowKey, monthNumber, newValue) => {
		setEditedValues((prev) => ({
			...prev,
			[rowKey]: {
				...prev[rowKey],
				[monthNumber]: newValue,
			},
		}));
	};

	const handleReasonChange = (rowKey, monthNumber, newReason) => {
		
		setEditReasons((prev) => ({
			...prev,
			[rowKey]: {
				...prev[rowKey],
				[monthNumber]: newReason,
			},
		}));
	};

	// Open detail offcanvas and fetch detail data
	const openDetailOffcanvas = (userId, fiscalYear, userName, role, endpointConfig) => {
		setShowingRevenueDetails(false);
		setSelectedUserId(userId);
		setViewingYear(fiscalYear);
		setOffCanvasTitle(`Fiscal ${fiscalYear} SIP Details for ${userName}`);
		setSelectedUserRole(role);
		setSelectedEndpointConfig(endpointConfig);
		fetchDetailData(userId, fiscalYear, endpointConfig, role);
		setShowOffcanvas(true);
	};

	// Fetch detail data
	const fetchDetailData = async (userId, fiscalYear, endpointConfig, role) => {
		setIsFetchingDetail(true);
		setDetailData(null);
		setDetailColumns([]);
		setOffCanvasError("");

		

		try {
			const response = await callIndexEndpoint(
				endpointConfig,
				{
					amplifiUserId: userId,
					fiscalYear,
				},
				endpointConfig.filterDataConfig
			);

			if (response.status === 200) {
				const fetchedData = response.data;
				const { transposedData, transposedColumns } = transformDetailData(fetchedData, fiscalYear, role);
				setDetailData(transposedData);
				setDetailColumns(transposedColumns);
			} else {
				console.error(response);
				setOffCanvasError("Failed to load detail data.");
			}
		} catch (error) {
			console.error(error);
			setOffCanvasError("Failed to load detail data.");
		} finally {
			setIsFetchingDetail(false);
		}
	};

	const renderTooltip = (value, props) => (
		<Tooltip id="button-tooltip" {...props}>
			<span>{value}</span>
		</Tooltip>
	);

	// Transform detail data into transposed format
	const transformDetailData = (fetchedData, fiscalYear, role) => {
		// Ensure months are unique and sorted
		const monthNumbers = [...new Set(fetchedData.map((item) => item.monthInYear))].sort((a, b) => a - b);

		const parameters = indexConfig.detailView.parameters.filter((p) => !p.requiresRole || p.requiresRole.includes(role));

		// Build transposed data, including formatters and month-specific data
		const transposedData = parameters.map(({ label, key, editable, requiresReason, ...formatters }) => {
			const row = { parameter: label, key, editable, requiresReason, formatters, monthData: {} };
			fetchedData.forEach((item) => {
				const value = item[key];
				const calculationStatus = item.calculationStatus; // Assuming calculationStatus is per month
				const reason = item[`${key}Reason`]; // Assuming reason is stored as 'adjustmentReason' or 'periodTargetReason'
				const monthNumber = item.monthInYear;

				// Store month-specific data
				row[monthNumber] = {
					value,
					originalValue: value,
					calculationStatus,
					reason,
				};

				// For easy access in cell renderer
				row.monthData[monthNumber] = {
					value,
					originalValue: value,
					calculationStatus,
					reason,
				};
			});
			return row;
		});

		// Determine the fiscal start date
		const fiscalStartDate = moment(`${fiscalYear}-04-01`); // Fiscal year starts in April

		// Map month numbers to formatted month names with years
		const monthHeaders = monthNumbers.map((monthNumber) => {
			const monthDate = fiscalStartDate.clone().add(monthNumber - 1, "months");
			const headerLabel = monthDate.format("MMM 'YY"); // e.g., "Apr '24"
			const accessor = String(monthNumber);

			return {
				Header: headerLabel,
				accessor: accessor,
				Cell: ({ value, row }) => {
					const formatters = row.original.formatters;

					if (formatters.amountFormattor) {
						return amountFormat(value, formatters.amountFormattor);
					} else if (formatters.percentageFormattor) {
						return percentageFormat(value, formatters.percentageFormattor);
					} else {
						return value;
					}
				},
			};
		});

		// Build transposed columns, applying formatters based on the parameter
		const transposedColumns = [
			...indexConfig.detailView.tableColumns,
			...monthHeaders.map((header) => ({
				...header,
				Cell: ({ value, row, column }) => {
					const monthNumber = column.id;
					const { key, editable, requiresReason } = row.original;
					const editRequiresRole = row.original.formatters.editRequiresRole;
					const monthData = row.original.monthData[monthNumber];
					const cellValue = monthData ? monthData.value ?? "" : value;
					const originalValue = monthData ? monthData.originalValue ?? "" : "";
					const calculationStatus = monthData ? monthData.calculationStatus : null;
					const reason = monthData ? monthData.reason : null;
					const reasonText = reason || "No reason provided";

					if (editable && (!editRequiresRole || editRequiresRole.includes(role)) && calculationStatus !== "Closed") {
						return (
							<EditableCell
								value={cellValue}
								originalValue={originalValue}
								requiresReason={requiresReason}
								rowKey={key}
								monthNumber={monthNumber}
								onValueChange={handleValueChange}
								existingReason={reason}
								onReasonChange={handleReasonChange}
							/>
						);
					} else {
						const formattedValue = formatCellValue(cellValue, row.original.formatters);

						return reason ? (
							<OverlayTrigger placement="left" overlay={renderTooltip(reasonText)}>
								<span>{formattedValue}</span>
							</OverlayTrigger>
						) : (
							<span>{formattedValue}</span>
						);
					}
				},
			})),
		];

		return { transposedData, transposedColumns };
	};

	// Function to call the API endpoint
	const callIndexEndpoint = async (endPointConfig, filterData = {}, filterDataConfig) => {
		let data = {};

		if (filterDataConfig) {
			filterDataConfig.map((config) => {
				let value = config.value ? config.value : config.accessor ? filterData[config.accessor] : "";
				data[config.name] = value;
			});
		} else {
			data = filterData;
		}

		if (Object.keys(filterData).length === 0) {
			// Fetch date filters from sessionStorage
			const sitewideFilters = JSON.parse(sessionStorage.getItem("sitewideFilters")) || {};

			const startDateObj = moment(sitewideFilters.startDate);
			const endDateObj = moment(sitewideFilters.endDate);
			const startOfMonth = startDateObj.clone().startOf("month").format("YYYY-MM-DD");
			const endOfMonth = startDateObj.clone().endOf("month").format("YYYY-MM-DD");

			if (endPointConfig.bodyNeeds) {
				endPointConfig.bodyNeeds.forEach((need) => {
					switch (need) {
						case "startDate":
							data.startDate = startDateObj.format("YYYY-MM-DD");
							break;
						case "endDate":
							data.endDate = endDateObj.format("YYYY-MM-DD");
							break;
						case "startOfMonth":
							data.startDate = startOfMonth;
							break;
						case "endOfMonth":
							data.endDate = endOfMonth;
							break;
						default:
							data[need] = filterData[need];
							break;
					}
				});
			}
		}

		// Merge additional filter data
		data = { ...data, ...filterData };
		setFileNameExtender(updateFileName(null, data, "_"));

		return apiRequest(process.env.REACT_APP_API_URL, endPointConfig.endPoint, endPointConfig.type || "get", data);
	};

	// Update column filters (if used)
	const updateColumnFilters = (id, value) => {
		setColumnFilters((prevFilters) => {
			// Check if filter with the same id already exists
			const existingFilterIndex = prevFilters.findIndex((filter) => filter.id === id);

			// Create a new array based on previous filters
			let newFilters;

			if (existingFilterIndex >= 0) {
				// If value is empty, remove the filter
				if (value === "" || value === null || value === undefined) {
					newFilters = prevFilters.filter((filter) => filter.id !== id);
				} else {
					// Update existing filter's value
					newFilters = prevFilters.map((filter, index) => (index === existingFilterIndex ? { id, value } : filter));
				}
			} else {
				// Add new filter if value is not empty
				if (value !== "" && value !== null && value !== undefined) {
					newFilters = [...prevFilters, { id, value }];
				} else {
					// If value is empty and filter doesn't exist, keep previous filters
					newFilters = prevFilters;
				}
			}

			return newFilters;
		});
	};

	const submitEdits = async () => {
		setIsFetchingDetail(true);
		try {
			if (!indexConfig.detailView.saveEndpoint) {
				console.error("Save endpoint not defined in configuration.");
				return;
			}

			const editsToSubmit = [];

			let userData = getSessionData();

			// Prepare data to submit
			for (const [key, months] of Object.entries(editedValues)) {
				for (const [monthNumber, newValue] of Object.entries(months)) {
					const detailRow = detailData.find((row) => row.key === key);

					const monthData = detailRow.monthData[monthNumber];

					//const role = deta

					const originalValue = monthData.originalValue;
					const calculationStatus = monthData.calculationStatus;

					// Skip if calculationStatus is "Closed"
					if (calculationStatus === "Closed") {
						continue;
					}

					// Check if value has changed
					if (parseFloat(newValue) !== parseFloat(originalValue)) {
						const reason = editReasons[key]?.[monthNumber];

						// If requiresReason and reason is empty, set error
						if (indexConfig.detailView.parameters.find((p) => p.key === key)?.requiresReason && !reason) {
							setOffCanvasError("Please provide a reason for all edits.");
							return;
						}

						editsToSubmit.push({
							userId: selectedUserId,
							fiscalYear: viewingYear,
							key,
							monthNumber: parseInt(monthNumber, 10),
							newValue: parseFloat(newValue),
							reason: reason || "",
							initiatingUserId: userData.userId,
						});
					}
				}
			}

			// If no edits, show a message or simply return
			if (editsToSubmit.length === 0) {
				setOffCanvasError("No changes have been made.");
			} else {
				// Call the save endpoint
				const response = await apiRequest(process.env.REACT_APP_API_URL, indexConfig.detailView.saveEndpoint.endPoint, indexConfig.detailView.saveEndpoint.type || "POST", editsToSubmit);

				if (response.status === 200) {
					// Successfully saved
					fetchDetailData(selectedUserId, viewingYear, selectedEndpointConfig, selectedUserRole);
					getIndexData();
					setEditedValues({});
					setEditReasons({});
					setShowOffcanvas(false);
				} else {
					setOffCanvasError("Failed to save changes.");
					console.error(response);
				}
			}
		} catch (error) {
			setOffCanvasError("An error occurred while saving changes.");
			console.error(error);
		} finally {
			setIsFetchingDetail(false);
		}
	};

	let renderTable = (maint) => {
		/* {maint.title && <h6 className="text-capitalize fw-bold">{maint.title}</h6>} */
		return (
			<Table
				pageIndexValue={currentPage}
				updatePageIndexValue={setCurrentPage}
				columnFilter={columnFilters}
				updateColumnFilter={updateColumnFilters}
				columns={maint.column}
				data={maint.data}
				reduceWidth={"90px"}
				reportTitle={maint.title}
				fileName={maint.fileName + (fileNameExtender ? "-" + fileNameExtender : "")}
				allowPagination={allowPaginationOnTable}
				manualSortBy
				expandedByDefault={maint.expandedByDefault}
				exportAllRows={true}
			/>
		);
	};

	return (
		<>
			{isFetchingConfig ? (
				<Loader message="Loading configuration..." />
			) : noFinanceIndexFound ? (
				<h2 className="text-center">No Index Found</h2>
			) : errorFound ? (
				<h2 className="text-center">Error Found in Index Config</h2>
			) : indexDetails ? (
				<>
					{/* Main Table */}
					<div className="px-2" style={{ height: "calc(95vh - 155px)" }}>
						<Row>
							<Col xs={6}>
								<h5 className="text-capitalize">{indexDetails.name}</h5>
							</Col>
						</Row>
						{isFetchingData ? (
							<Loader message="Loading data..." />
						) : (
							index &&
							(indexConfig.tabGroups ? (
								<div className="finance-tabs h-100">
									<Tabs variant="tabs" defaultActiveKey={indexConfig.tabGroups[0].componentName} id={indexConfig.tabGroups[0].tabGroupName}>
										{indexConfig.tabGroups.map((tabComponent) => {
											let maint = index.find((i) => i.report === tabComponent.report);

											return (
												<Tab eventKey={tabComponent.componentName} title={tabComponent.componentName} key={tabComponent.componentName} className="h-100">
													<div className="card h-100">
														<div className="card-body h-100">{renderTable(maint)}</div>
													</div>
												</Tab>
											);
										})}
									</Tabs>
								</div>
							) : (
								index.map((maint, i) => <React.Fragment key={i}>{renderTable(maint)}</React.Fragment>)
							))
						)}
					</div>
					{indexConfig.detailView && (
						<DynamicOffcanvas
							show={showOffcanvas}
							onHide={() => {
								setShowOffcanvas(false);
							}}
							title={offCanvasTitle}
							errorMessage={offCanvasError}
							errorType={offCanvasErrorType}
							onErrorClose={() => setOffCanvasError("")}
							width="85%"
							footerButtons={
								indexConfig &&
								!showingRevenueDetails && (
									<>
										<Button variant="danger" className="me-2" onClick={() => setShowOffcanvas(false)}>
											<FontAwesomeIcon icon="xmark" size="lg" /> Cancel
										</Button>
										<Button variant="primary" onClick={() => submitEdits()}>
											<FontAwesomeIcon icon="floppy-disk" /> {indexConfig.detailView.saveEndpoint.buttonText ?? "Save Changes"}
										</Button>
									</>
								)
							}>
							<div style={{ position: "relative" }}>
								{/* Loader overlay */}
								{isFetchingDetail && (
									<div
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											right: 0,
											bottom: 0,
											zIndex: 1700, // Ensure this is higher than any other z-index in your content
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional semi-transparent background
										}}>
										<Loader message="Loading..." />
									</div>
								)}
								{/* Always render the content */}
								<div className="offcanvas-content">
									{detailData && (
										<div className="m-3 pe-4" style={{ maxHeight: showingRevenueDetails ? "calc(50% + 10px)" : "calc(120% - 120px)" }}>
											<Table
												columns={detailColumns}
												data={detailData}
												reportTitle={offCanvasTitle}
												scrollable={showingRevenueDetails}
												allowPagination={showingRevenueDetails ? "yes" : "no"}
												tableHeight={650}
												manualSortBy
											/>
										</div>
									)}
								</div>
							</div>
						</DynamicOffcanvas>
					)}
				</>
			) : (
				<Loader message="Loading configuration..." />
			)}
		</>
	);
};

export default Finance;
