import React, { useState, useEffect } from "react";
import { Button, Row, Col, Card, Spinner, Badge } from "react-bootstrap";
import { apiRequest } from "./api/Request";
import MultiSelect from "./multiSelect";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../custom.css";

const UserStaffsEditor = ({ userId, userStaffs, updateStaffs, asTabbedContent = false }) => {
	const [uStaffs, setUStaffs] = useState([]);
	const [availableStaffs, setAvailableStaffs] = useState([]);
	const [initialStaffIds, setInitialStaffIds] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getStaffs = async () => {
			setIsLoading(true);
			let reqRes = await apiRequest(process.env.REACT_APP_API_URL, "Maintenance/get-staffs", "get");

			let staffData = reqRes.data;

			const dataToSet = staffData.map((sd) => {
				let name = `${sd.firstName} ${sd.lastName}: ${sd.sourceKey}`;
				const isDisabled = sd.currentMappingId && sd.currentMappingId !== userId;
				if (sd.currentMapping && isDisabled) {
					name += ` (Currently mapped to: ${sd.currentMapping})`;
				}
				return {
					id: sd.staffKey,
					name: name,
					disabled: isDisabled,
				};
			});

			setAvailableStaffs(dataToSet);
			setIsLoading(false);
		};

		getStaffs();
	}, []);

	useEffect(() => {
		// Only reset initialStaffIds and uStaffs when userId changes
		let initialStaffIds = [];
		if (userStaffs != null) {
			if (typeof userStaffs === "string") {
				initialStaffIds = JSON.parse(userStaffs).map(Number);
			} else if (Array.isArray(userStaffs)) {
				initialStaffIds = userStaffs.map(Number);
			}
		}
		setInitialStaffIds(initialStaffIds);
		setUStaffs(initialStaffIds);
	}, [userId]);

	const handleSelectChange = (ids) => {
		setUStaffs(ids);
		updateStaffs(ids);
	};

	const handleRemoveStaff = (id) => {
		const newSelectedStaffs = uStaffs.filter((staffId) => staffId !== id);
		setUStaffs(newSelectedStaffs);
		updateStaffs(newSelectedStaffs);
	};

	// const selectedStaffs = uStaffs.map((staffId) => availableStaffs.find((staff) => staff.id === staffId)).filter(Boolean);

	const newlyAddedStaffIds = uStaffs.filter((id) => !initialStaffIds.includes(id));

	// Get details for selected and removed staffs
	const selectedStaffs = uStaffs.map((staffId) => availableStaffs.find((staff) => staff.id === staffId)).filter(Boolean);

	const initialSelectedStaffs = selectedStaffs.filter((staff) => initialStaffIds.includes(staff.id));
	const newSelectedStaffs = selectedStaffs.filter((staff) => !initialStaffIds.includes(staff.id));
	const newlyRemovedStaffIds = initialStaffIds.filter((id) => !uStaffs.includes(id));

	const removedStaffs = newlyRemovedStaffIds.map((staffId) => availableStaffs.find((staff) => staff.id === staffId)).filter(Boolean);

	// Combine for rendering
	const displayedStaffs = [...selectedStaffs, ...removedStaffs];

	//const orderedSelectedStaffs = [...initialSelectedStaffs, ...newSelectedStaffs];

	const renderBadge = (status) => {
		const addedBadgeStyle = {
			fontSize: "10px",
			padding: "3px 7px",
			borderRadius: "5px",
			marginLeft: "5px",
			backgroundColor: "#D9FBF2",
			color: "#3BAF8D",
			border: "1px solid #3BAF8D",
		};

		const removedBadgeStyle = {
			fontSize: "10px",
			padding: "3px 7px",
			borderRadius: "5px",
			marginLeft: "5px",
			backgroundColor: "#FFEFEF",
			color: "#D9534F",
			border: "1px solid #D9534F",
		};

		return (
			<Badge bg="" style={status === "ADDED" ? { ...addedBadgeStyle } : { ...removedBadgeStyle }}>
				{status}
			</Badge>
		);
	};

	return (
		<Row className="w-100" key={"offcanvas_staffMapping"}>
			<Col>
				<Card className="mx-3">
					<Card.Header>
						<Row>
							<Col md={{ span: 10 }}>
								<h5>Staff Mapping</h5>
							</Col>
						</Row>
					</Card.Header>
					<Card.Body className="userStaffMapping-card pt-3">
						<Row className="h-100">
							<Col md={{ span: 6 }}>
								{isLoading ? (
									<div className="widget-loader">
										<Spinner animation="border" role="status">
											<span className="sr-only">Loading...</span>
										</Spinner>
									</div>
								) : (
									<MultiSelect
										keepOpen={true}
										selectAllCheck={false}
										options={availableStaffs}
										onChange={handleSelectChange}
										selectedOptions={uStaffs}
										singularNoun={"Staff Record"}
										disabled={false}
									/>
								)}
							</Col>
							<Col md={{ span: 6 }}>
								<Card className="h-100">
									<Card.Header>Selected Staff Records</Card.Header>
									<Card.Body>
										{isLoading ? (
											<div className="widget-loader">
												<Spinner animation="border" role="status">
													<span className="sr-only">Loading...</span>
												</Spinner>
											</div>
										) : (
											<div className="selectedStaffOptions">
												{displayedStaffs.map((staff) => {
													const isAdded = newlyAddedStaffIds.includes(staff.id);
													const isRemoved = newlyRemovedStaffIds.includes(staff.id);

													return (
														<div key={staff.id} className={`d-flex align-items-center mb-2 ${isRemoved ? "text-muted" : ""}`}>
															{!isRemoved && (
																<Button className="text-center me-2 noBorder" size="sm" variant="outline-danger" onClick={() => handleRemoveStaff(staff.id)}>
																	<FontAwesomeIcon icon="trash-xmark" />
																</Button>
															)}
															<strong style={isRemoved ? { textDecoration: "line-through" } : {}}>{staff.name}</strong>
															{isAdded && renderBadge("ADDED")}
															{isRemoved && renderBadge("REMOVED")}
														</div>
													);
												})}
											</div>
										)}
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

UserStaffsEditor.propTypes = {
	userStaffs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.string]),
	updateStaffs: PropTypes.func,
	userId: PropTypes.number,
	asTabbedContent: PropTypes.bool,
};

export default UserStaffsEditor;
