import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import logo from "../../assets/AudioNovaLogoVertical_Trimmed.png";
import '../../Styles/Login.css';

export const HandleSignIn = () => {
    const { instance } = useMsal();

    const handleLogin = async (loginType) => {
        try {
            const loginResponse =
                loginType === "popup"
                    ? await instance.loginPopup(loginRequest)
                    : await instance.loginRedirect(loginRequest);
            if (loginResponse) {
                const currentTime = new Date().getTime();
                localStorage.setItem("amplifi_loginTime", currentTime);
            }
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <h5>
                <center>
                    <Container className="d-flex align-items-center justify-content-center vh-100">
                        <Row className="justify-content-center w-100">
                            <Col md={4}>
                                <Card className="px-5 py-5 shadow" variant="light">
                                    <div className="form-data">
                                        <div className="text-center">
                                            <img
                                                src={logo}
                                                alt="Sonova Audiological Care U.S."
                                                width="325px"
                                            />
                                        </div>
                                        <div>
                                            {/*<p className="my-3"> Amplifi 2.0 </p>*/}
                                            <Button
                                                variant="audionovaGreen"
                                                className="w-100 mt-5"
                                                onClick={() => handleLogin("popup")}
                                                title="Sign in"
                                            >
                                                Sign in
                                            </Button>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </center>
            </h5>
        </>
    );
};
