import React from "react";
import { amountFormat, percentageFormat, booleanFormat, linkFormat, dateFormat, buttonFormat } from "./Helper";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const defaultIsEmpty = (obj) => {
	return Object.keys(obj).length === 0;
};

const defaultAmountFormattorCell = (props) => {
	return amountFormat(props.value, props.column.amountFormattor);
};

const defaultPercentageFormattorCell = (props) => {
	return percentageFormat(props.value, props.column.percentageFormattor);
};

const defaultBooleanFormattorCell = (props) => {
	return booleanFormat(props.value, props.column.booleanFormattor);
};

const defaultLinkFormattorCell = (props) => {
	return linkFormat(props.value, props.row.values.source, props.column.linkFormattor);
};

const defaultDateFormattorCell = (props) => {
	return dateFormat(props.value, props.column.dateFormattor);
};

const defaultButtonFormattorCell = ({ value, row, column }) => {
	const handleClick = column.handleClick || (() => {});
	return buttonFormat(value, column.buttonFormattor, handleClick);
};

const columnMap = (columns, options = {}) => {
	const {
		includeConditionalRendering = false,
		isEmpty = defaultIsEmpty,
		amountFormattorCell = defaultAmountFormattorCell,
		percentageFormattorCell = defaultPercentageFormattorCell,
		booleanFormattorCell = defaultBooleanFormattorCell,
		linkFormattorCell = defaultLinkFormattorCell,
		dateFormattorCell = defaultDateFormattorCell,
		buttonFormattorCell = defaultButtonFormattorCell,
		includeExpander = false,
	} = options;

	return columns.map((column) => {
		const newColumn = { ...column };

		// Handle expander column
		if (includeExpander && (newColumn.id === "expander" || newColumn.expander)) {
			newColumn.Cell = ({ row }) => {
				return row.canExpand ? (
					<div {...row.getToggleRowExpandedProps()} style={{ cursor: "pointer" }}>
						<FontAwesomeIcon icon="triangle" rotation={row.isExpanded ? 180 : 90} size="2xs" />
					</div>
				) : null;
			};
			// Return early to prevent overwriting Cell
			return newColumn;
		}

		// Handle hidden columns
		if (newColumn.hiddenColumn) {
			newColumn.show = false;
		}

		// Recursively handle nested columns
		if (newColumn.columns) {
			newColumn.columns = columnMap(newColumn.columns, options);
		}

		// Apply formatters based on configuration
		let cellFormatter;

		if (newColumn.buttonFormattor) {
			cellFormatter = buttonFormattorCell;
		} else if (newColumn.amountFormattor) {
			cellFormatter = amountFormattorCell;
		} else if (newColumn.percentageFormattor) {
			cellFormatter = percentageFormattorCell;
		} else if (newColumn.booleanFormattor) {
			cellFormatter = booleanFormattorCell;
		} else if (newColumn.linkFormattor) {
			cellFormatter = linkFormattorCell;
		} else if (newColumn.dateFormattor) {
			cellFormatter = dateFormattorCell;
		} else {
			cellFormatter = (props) => props.value;
		}

		// Conditional rendering if enabled
		if (includeConditionalRendering) {
			const originalCell = cellFormatter;

			newColumn.Cell = (props) => {
				const { row } = props;
				const rowType = row.values.source;
				const rowDepth = row.depth;

				// Handle show/hide based on 'show' property
				if (newColumn.show === false) {
					return null;
				}

				// Handle conditionalRendering property in column configuration
				if (newColumn.conditionalRendering) {
					const { accessor: condAccessor, values: condValues } = newColumn.conditionalRendering;
					const cellValue = row.original[condAccessor];

					if (condValues.includes(cellValue)) {
						// Condition met, render the cell as per formatter (e.g., button)
						return originalCell(props);
					} else {
						// Condition not met, render the cell without the button
						// Choose the appropriate formatter excluding button
						let nonButtonFormatter;

						if (newColumn.amountFormattor) {
							nonButtonFormatter = amountFormattorCell;
						} else if (newColumn.percentageFormattor) {
							nonButtonFormatter = percentageFormattorCell;
						} else if (newColumn.booleanFormattor) {
							nonButtonFormatter = booleanFormattorCell;
						} else if (newColumn.linkFormattor) {
							nonButtonFormatter = linkFormattorCell;
						} else if (newColumn.dateFormattor) {
							nonButtonFormatter = dateFormattorCell;
						} else {
							nonButtonFormatter = (props) => props.value;
						}

						return nonButtonFormatter(props);
					}
				}

				// Existing conditional rendering logic...
				if (!column.ignoreConditionalHide) {
					if (
						(rowType === "TERRITORY AGGREGATE" && ["regionName", "clinicName", "source", "rcCode"].includes(column.accessor)) ||
						(rowType === "REGIONAL AGGREGATE" && ["regionName", "clinicName", "source", "rcCode"].includes(column.accessor)) ||
						(rowType === "TOTAL AGGREGATE" && ["territory", "regionName", "clinicName", "source", "rcCode"].includes(column.accessor)) ||
						(rowDepth > 0 && !["TERRITORY AGGREGATE", "REGIONAL AGGREGATE"].includes(rowType) && ["territory", "regionName"].includes(column.accessor))
					) {
						return null;
					}
				}

				if (column.canExpand && row.original.name && !isEmpty(row.original.name)) {
					return (
						<div style={{ cursor: "pointer", marginLeft: rowDepth * 20 }}>
							<span {...row.getToggleRowExpandedProps()}>
								<Row>
									<Col md={2}>
										<FontAwesomeIcon icon="triangle" rotation={row.isExpanded ? 180 : 90} size="2xs" />
									</Col>
									<Col md={10} style={{ textWrap: "nowrap" }}>
										{row.original.name}
									</Col>
								</Row>
							</span>
						</div>
					);
				}

				return originalCell(props);
			};
		} else {
			// If conditional rendering is not enabled, set the Cell to the formatter
			newColumn.Cell = cellFormatter;
		}

		return newColumn;
	});
};

export default columnMap;
