import React from 'react';
import { Offcanvas, Button, Alert, Fade } from 'react-bootstrap';
import '../../custom.css';

const DynamicOffcanvas = ({
  show,
  onHide,
  title,
  children,
  footerButtons,
  errorMessage,
  onErrorClose,
  errorType = 'warning',
  width = '85%',
}) => {
  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement="end"
      style={{ zIndex: 1400, width, top: '73px', height: 'calc(100vh - 73px)' }}
      backdrop="static"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="ps-4">{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {errorMessage && (
          <Alert
            variant={errorType}
            dismissible
            onClose={onErrorClose}
            transition={Fade}
          >
            {errorMessage}
          </Alert>
        )}
        {children}
        {footerButtons && (
          <div className="offcanvas-footer">
            <div className="text-end">{footerButtons}</div>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default DynamicOffcanvas;