function deepMerge(target, source) {



    if (typeof target !== 'object' || target === null || Array.isArray(target)) {
        // If target is not an object (including being null or an array), replace it entirely with source
        return source;
    }

    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            if (typeof source[key] === 'object' && source[key] !== null && !Array.isArray(source[key]) && key in target) {
                // Only recurse if source[key] is an object, not an array, not null, and exists in target
                target[key] = deepMerge(target[key], source[key]);
            } else {
                // For arrays or primitives, directly set the value
                target[key] = source[key];
            }
        }
    }
    return target;
}

export default deepMerge;