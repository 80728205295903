import { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import ReactECharts from "echarts-for-react";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/DynamicChart.css";
import WatermarkWidget from "./watermarkWidget";
import WatermarkRegionalWidget from "./watermarkRegionalWidget";

const DynamicChart = ({ chartName, reportUrl, chartOptions, chartType, chartSize, extraText, popoverTitle, popoverText, keyProp }) => {
	const chartRef = useRef(null);

	const disposeChart = useCallback(() => {
		if (chartRef.current) {
			const instance = chartRef.current.getEchartsInstance();
			if (instance) {
				instance.dispose();
			}
		}
	}, []);

	useEffect(() => {
		return () => {
			disposeChart();
		};
	}, [disposeChart]);

	useEffect(() => {
		if (chartRef.current) {
			const instance = chartRef.current.getEchartsInstance();
			instance.setOption(chartOptions, true);
		}
	}, [chartOptions]);

	// Function to determine chart height
	const getChartHeight = () => {
		if (chartSize === "Half" || chartSize === "Skinny") {
			return "70px"; // Adjust height for Half-Sized Charts
		} else if (chartType === "Arc Gauge") {
			return extraText.length > 0 ? "220px" : "300px"; //Arc Gauges need a bit more height to fit correctly
		} else {
			return extraText.length > 0 ? "235px" : "245px"; // Height for Single, Double, and Quad-Charts
		}
	};

	const renderContent = () => {
		let oneNumberContent = null;
		let stackedLineContent = [];
		let multiLineContent = [];
		let oneLineContent = null;

		extraText.forEach((item, index) => {
			const { type, label, prefix, value, unit, goal, colors, goalDirection, labelStyle, valueStyle } = item;

			let parsedValue = /*chartType === "Big Number" ? parseFloat(unit) :*/ parseFloat(value);
			let parsedGoal = parseFloat(goal);

			if (isNaN(parsedValue)) parsedValue = 0; // Default value if parsing fails
			if (isNaN(parsedGoal)) parsedGoal = 0; // Default value if parsing fails

			if (type === "One Number") {
				// Single number large display
				oneNumberContent = (
					<Col key={index} className="text-center">
						<div
							style={{
								fontSize: "32px",
								fontWeight: "bold",
								color: colors ? (parsedValue >= parsedGoal ? colors[0] : colors[1]) : "#666",
								marginTop: chartOptions ? "-10px" : "10px",
								...valueStyle,
							}}>
							{(prefix ? prefix : "") + value + unit}
						</div>
					</Col>
				);
			} else if (type === "Stacked Line") {
				// Additional details, vertically stacked
				stackedLineContent.push(
					<div key={index} style={{ fontSize: "14px", marginTop: "0px", ...labelStyle }}>
						<strong>{label}</strong>{" "}
						<span style={{ ...valueStyle }}>
							{prefix}
							{value} {unit}
						</span>
					</div>
				);
			} else if (type === "MultiLine") {
				multiLineContent.push(
					<div key={index} style={{ fontSize: "14px", marginTop: "0px", ...labelStyle }}>
						<strong>{label}</strong>{" "}
						<span style={{ ...valueStyle }}>
							{prefix}
							{value} {unit}
						</span>
					</div>
				);
			} else if (type === "One Line") {
				// Single line below chart
				oneLineContent = (
					<Row key={index}>
						<Col>
							<div
								style={{
									textAlign: "center",
									paddingTop: "0px",
									color: "#666",
									...labelStyle,
								}}>
								{label}{" "}
								<strong
									style={{
										color:
											goal == null || colors == null
												? "#666"
												: (goalDirection === "higher" && value >= goal) || (goalDirection === "lower" && value <= goal)
												? colors[0]
												: colors[1],
										...valueStyle,
									}}>
									{value} {unit}
								</strong>
							</div>
						</Col>
					</Row>
				);
			}
		});

		if (extraText.length > 0) {
			if (chartType === "Big Number" || chartType === "Total List") {
				return (
					<a className="blackLink" href={"/Reports/" + reportUrl}>
						<Row style={{ marginTop: chartType === "Big Number" || chartType === "Total List" ? "35px" : "0px" }}>
							<Col md={4}>{oneNumberContent}</Col>
							<Col md={8} style={{ marginTop: chartOptions ? "-10px" : "10px" }}>
								{stackedLineContent}
								{multiLineContent}
							</Col>
						</Row>
						{oneLineContent}
					</a>
				);
			} else {
				return (
					<>
						<Row style={{ marginTop: chartType === "Big Number" ? "35px" : "0px" }}>
							<Col md={oneNumberContent ? 4 : 0}>{oneNumberContent}</Col>
							<Col md={oneNumberContent ? 8 : 12} style={{ marginTop: chartOptions ? "-10px" : "10px" }} className={oneNumberContent ? "" : "text-center"}>
								{stackedLineContent}
							</Col>
						</Row>
						{oneLineContent}
					</>
				);
			}
		} else {
			return undefined;
		}
	};

	const popover = (
		<Popover id="info-popover" style={{ position: "absolute" }}>
			<Popover.Header as="h3">{chartName + " Description"}</Popover.Header>
			<Popover.Body>
				<div dangerouslySetInnerHTML={{ __html: popoverText }} />
			</Popover.Body>
		</Popover>
	);

	return (
		<>
			<Row>
				<Col>
					<Row style={{ fontSize: "14px", color: "#000", position: "relative" }}>
						<Col md={12}>
							<strong>{chartName}</strong>
							<OverlayTrigger placement="bottom" overlay={popover} container={document.body}>
								<FontAwesomeIcon icon="info-circle" className={"float-end info-icon"} style={{ color: "#666" }} />
							</OverlayTrigger>
						</Col>
					</Row>
					{chartType === "Watermark Custom" ? (
						<WatermarkWidget warning={chartOptions.warning} watermarks={chartOptions.watermarks} acknowledgement={chartOptions.acknowledgement} clinic={chartOptions.clinic} />
					) : chartType === "Watermark Regional" ? (
						// Assuming you have a similar component for "Watermark Regional"
						<WatermarkRegionalWidget watermarkHistories={chartOptions.watermarkHistories} />
					) : (
						chartOptions &&
						Object.keys(chartOptions).length > 0 && (
							<Row>
								<Col md={12}>
									<a href={"/Reports/" + reportUrl}>
										<ReactECharts
											ref={chartRef}
											className={"eChartsATag " + chartType.replace(/ /g, "")}
											option={chartOptions}
											style={{ height: getChartHeight(), width: "100%" }}
											key={keyProp}
										/>
									</a>
								</Col>
							</Row>
						)
					)}
				</Col>
			</Row>
			{extraText.length > 0 ? renderContent() : null}
		</>
	);
};

DynamicChart.propTypes = {
	chartName: PropTypes.string.isRequired,
	chartOptions: PropTypes.object,
	chartType: PropTypes.string.isRequired,
	chartSize: PropTypes.string.isRequired,
	extraText: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired, // a string for "type"
			label: PropTypes.string.isRequired, // a string for "label"
			prefix: PropTypes.string, //a string for the value prefix
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // a string or number for "value"
			unit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // a string for "unit"
			goal: PropTypes.number, // an optional number for "goal"
			colors: PropTypes.arrayOf(PropTypes.string), // an optional array of strings for "colors"
			goalDirection: PropTypes.string,
		})
	),
	popoverTitle: PropTypes.string.isRequired,
	popoverText: PropTypes.string.isRequired,
	keyProp: PropTypes.string.isRequired,
};

export default DynamicChart;
