import React, { useState, useEffect } from 'react';
import { Col, Row, Stack, Card, OverlayTrigger, Popover, Button, Form, ButtonGroup, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from "prop-types";
import "../custom.css"; // Import the CSS for styling
import "../Styles/WatermarkRegionalWidget.css";

const WatermarkRegionalWidget = ({ watermarkHistories }) => {

    const prepareData = (watermarkHistories, uniqueDates) => {
        return watermarkHistories.map(clinic => {
            const dateMappedData = new Map(uniqueDates.map(date => [date, "No Data"]));
            let successCount = 0;
            let acknowledgementCount = 0;
            let totalEntries = clinic.watermarkHistories.length; // Total entries for each clinic

            clinic.watermarkHistories.forEach(history => {
                if (dateMappedData.has(history.waterMarkDateString)) {
                    dateMappedData.set(history.waterMarkDateString, {
                        ...history,
                        icon: history.acknowledgementStatus ? "check" : "xmark",
                        matchColor: history.acknowledgementStatus === history.success ? 'green' : 'red',
                        successColor: history.success ? 'green' : 'red'
                    });
                }
                // Increment the counts based on conditions
                if (history.success) {
                    successCount++;
                }
                if (history.acknowledgementStatus) {
                    acknowledgementCount++;
                }
            });

            // Calculate percentages
            const systemRate = (successCount / totalEntries * 100).toFixed(0);
            const clinicMatchRate = (acknowledgementCount / totalEntries * 100).toFixed(0);

            return {
                ...clinic,
                systemRate, // Add system rate to clinic object
                clinicMatchRate, // Add match rate to clinic object
                dates: Array.from(dateMappedData.values()) // Convert the Map back into an array
            };
        });
    };
    

    // Create a Set to store unique dates
    const allDates = new Set();
    watermarkHistories.map(clinic =>

        clinic.watermarkHistories.map(history =>
            allDates.add(history.waterMarkDateString))
    );

    

    // Convert the Set to an Array and reverse if needed
    const uniqueDates = Array.from(allDates).sort();

    

    const formattedData = prepareData(watermarkHistories, uniqueDates);


    return (

        <Row>
            <Col md={12}>
                <div className="watermarkHistories">
                    <Table className="table-borderless">
                        <thead className="sticky-header">
                            <tr>
                                <th className="fixed-width" style={{ width: "25%" }}>Clinic</th>
                                <th className="fixed-width" style={{ width: "6%" }}>Success</th>
                                <th className="fixed-width" style={{ width: "6%" }}>Match</th>
                                <th style={{ width: "5%" }}></th>
                                {uniqueDates.map((date, index) => (
                                    <th key={index} className="text-center"> {date} </th>
                                ))}
                                {/*{uniqueDates.map((date, index) => (*/}
                                {/*    <th key={index * 2} className="text-center"> {date} </th>*/}
                                {/*))}*/}
                            </tr>
                        </thead>
                        <tbody>
                            {formattedData.map((clinic, index) => (
                                <React.Fragment key={clinic.clinicKey}>
                                    <tr>
                                        <td className="fixed-width fw-bold">{clinic.clinicName}</td>
                                        <td className="fixed-width" style={{ color: '#00BFFF', fontSize: '18px' }}>
                                            {clinic.systemRate}%
                                        </td>
                                        <td className="fixed-width" style={{ color: '#00BFFF', fontSize: '18px' }}>
                                            {clinic.clinicMatchRate}%
                                        </td>
                                        <td>
                                            <div>
                                                <Stack key={index + "-labels"} className="text-center" style={{ width: '15px', fontSize: '9px' }}>
                                                    <small className="text-uppercase" style={{ marginTop: "13px" }}>Match</small>
                                                    <small className="text-uppercase">System</small>
                                                </Stack>
                                            </div>
                                        </td>
                                        {clinic.dates.map((dateInfo, idx) => (
                                            <td key={idx} >
                                                <Stack key={idx} className="text-center">
                                                    {dateInfo === "No Data" ? (
                                                        <small className="text-muted" style={{ fontSize: '10px' }}>No Data</small>
                                                    ) : (
                                                        <>
                                                            <FontAwesomeIcon
                                                                icon={dateInfo.icon}
                                                                color={"#000000"}
                                                                style={{ fontSize: '14px', marginBottom: '2px' }}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={"circle"}
                                                                color={dateInfo.matchColor}
                                                                style={{ fontSize: '7px', marginBottom: '2px' }}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={"circle"}
                                                                color={dateInfo.successColor}
                                                                style={{ fontSize: '7px', marginBottom: '2px' }}
                                                            />
                                                        </>
                                                    )}
                                                </Stack>
                                            </td>
                                        ))}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    );
};

WatermarkRegionalWidget.propTypes = {
    watermarkHistories: PropTypes.arrayOf(
        PropTypes.shape({
            clinicKey: PropTypes.number.isRequired,
            clinicName: PropTypes.string.isRequired,
            watermarkHistories: PropTypes.arrayOf(
                PropTypes.shape({
                    watermarkHistoryKey: PropTypes.number.isRequired,
                    dateKey: PropTypes.number.isRequired,
                    clinicKey: PropTypes.number.isRequired,
                    clinicName: PropTypes.string.isRequired,
                    waterMarkDate: PropTypes.string.isRequired,
                    waterMarkDateString: PropTypes.string.isRequired,
                    totalWaterMarks: PropTypes.number.isRequired,
                    filledWaterMarks: PropTypes.number.isRequired,
                    factTimestamp: PropTypes.string.isRequired,
                    success: PropTypes.bool.isRequired,
                    acknowledgementStatus: PropTypes.bool,
                    acknowledgementReason: PropTypes.string,
                    acknowledgedByName: PropTypes.string
                })
            ).isRequired
        })
    ).isRequired
};




export default WatermarkRegionalWidget;
