import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import logo from "../../assets/AudioNovaLogoVertical_Trimmed.png";

export const UnauthorizedUserDisplay = () => {
  const handleLogout = () => {
    window.open("/LogOut", "_self");
  };

  return (
    <h5>
      <center>
        <Container className="d-flex align-items-center justify-content-center vh-100">
          <Row className="justify-content-center w-100">
            <Col md={6}>
              <Card className="px-5 py-5 shadow" variant="light">
                <Card.Body>
                  <div className="text-center">
                    <img
                      src={logo}
                      alt="Sonova Audiological Care U.S."
                      className="w-100"
                    />
                  </div>
                  <hr />
                  <Row>
                    <Col>
                      The account you are attempting to log in with does not
                      have permission to access Amplifi.
                      <br />
                      <br />
                      If you think this is incorrect, please contact your system
                      administrator for access.
                      <br />
                      <br />
                      If you would like to try to log in with a different
                      account, click the button below to log out of your current
                      Microsoft session.
                      <br />
                      <br />
                      <strong>Warning:</strong> Logging out of this account will
                      log you out of all Microsoft applications in this browser
                      session.
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Button onClick={handleLogout}>Log Out</Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </center>
    </h5>
  );
};
