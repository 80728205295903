import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../custom.css";
import Dashboard from "./Dashboard";
import Report from "./Report";
import { HandleSignIn } from "../Components/auth/Login.jsx";
import { LogOut } from "../Components/auth/LogOut.jsx";
import Loader from "../Components/loader/Loader";
import Layout from "./Layout";
import { apiRequest } from "../Components/api/Request";
import { UnauthorizedUserDisplay } from "../Components/auth/UnauthUser.jsx";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import NotFound from "../Components/common/NotFound";
import { getSessionData } from "../Components/security/Helper";
import Maintenance from "./Maintenance.jsx";
import Finance from "./Finance.jsx";
import AccessDenied from "../Components/common/AccessDenied.jsx";

function UnAuthorized() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/UnauthorizedUser");
	}, [navigate]);

	return <div>UnAuthorized</div>;
}

const App = () => {
	const [isAllowedUser, setIsAllowedUser] = useState(true);
	const [isSearching, setIsSearching] = useState(false);
	const [isUserValidated, setIsUserValidated] = useState(false);
	const isAuthenticated = useIsAuthenticated();
	const { accounts } = useMsal();

	const [filterOptions, setFilterOptions] = useState({});
	const [filtersEnabled, setFiltersEnabled] = useState(true);

	const [selectedSort, setSelectedSort] = useState(JSON.parse(sessionStorage.getItem("selectedSort")));
	const [availableFilters, setAvailableFilters] = useState(sessionStorage.getItem("availableFilters"));
	const [selectedGrouping, setSelectedGrouping] = useState(JSON.parse(sessionStorage.getItem("selectedGrouping")));
	const [availableGroupings, setAvailableGroupings] = useState(JSON.parse(sessionStorage.getItem("availableGroupings")));

	useEffect(() => {
		if (isAuthenticated) {
			validateUser();
		}
	}, [isAuthenticated]);

	useEffect(() => {
		const sessionSort = JSON.parse(sessionStorage.getItem("selectedSort"));
		const sessionFilters = sessionStorage.getItem("availableFilters");

		const sessionGrouping = JSON.parse(sessionStorage.getItem("selectedGrouping"));

		if (sessionSort) {
			setSelectedSort(sessionSort);
		} else {
			setSelectedSort("alpha");
		}
		if (sessionFilters) {
			setAvailableFilters(sessionFilters);
		} else {
			setAvailableFilters(("sort", "date", "workshops", "clinics", "providers"));
		}
		if (sessionGrouping) {
			setSelectedGrouping(sessionGrouping);
		} else {
			setSelectedGrouping("Clinic");
		}
	}, []);

	const validateUser = async () => {
		setIsAllowedUser(null);
		setIsSearching(true);
		try {
			let getSessionDataResponse = getSessionData();
			if (getSessionDataResponse) {
				setIsAllowedUser(true);
			} else {
				const email = accounts[0].username;
				const response = await apiRequest(process.env.REACT_APP_API_URL, `Security/validateUser?email=${encodeURIComponent(email)}`, "GET");
				if (response.status === 200 && response.data.isValidUser) {
					

					localStorage.setItem("amplifi_user_data", JSON.stringify(response.data.userData));
					localStorage.setItem("displayed_role_data", JSON.stringify(response.data.roleData));
					setIsAllowedUser(true);
				} else {
					setIsAllowedUser(false);
				}
			}
			setIsUserValidated(true);
			setIsSearching(false);
		} catch (error) {
			console.error("Error validating user:", error);
			setIsSearching(false);
      setIsAllowedUser(false);
			return false;
		}
	};

	const handleClickApply = (filterJSON) => {
		setFilterOptions(filterJSON);
		sessionStorage.setItem("sitewideFilters", JSON.stringify(filterJSON));
	};

	const handleIsAllWidgetsLoaded = (allLoaded) => {
		setFiltersEnabled(allLoaded);
	};

	const handleSortingChange = (newSort) => {
		setSelectedSort(newSort);
		sessionStorage.setItem("selectedSort", JSON.stringify(newSort));
	};

	const handleAvailableGroupingChange = (newAvailableGroupings) => {
		setAvailableGroupings(newAvailableGroupings);
		sessionStorage.setItem("availableGroupings", JSON.stringify(newAvailableGroupings));

		if (newAvailableGroupings == null) {
			//sessionStorage.setItem("selectedGrouping", "Clinic");
			handleGroupingChange("Clinic");
		}
	};

	const handleGroupingChange = (newGrouping) => {
		setSelectedGrouping(newGrouping);
		sessionStorage.setItem("selectedGrouping", JSON.stringify(newGrouping));
	};

	return (
		<>
			<Router>
				<AuthenticatedTemplate>
					{(isSearching || !isUserValidated || isAllowedUser === null) && <Loader message="Loading..." />}
					{isUserValidated &&
						(isAllowedUser ? (
							<Layout
								onClickApply={handleClickApply}
								onSortingChange={handleSortingChange}
								onGroupingChange={handleGroupingChange}
								onAvailableGroupingChange={handleAvailableGroupingChange}
								selectedSort={selectedSort}
								selectedGrouping={selectedGrouping}
								availableGroupings={availableGroupings}
								filtersEnabled={filtersEnabled}>
								<Routes>
									<Route path="/" element={<Navigate to="/Dashboard" />} />
									<Route path="/UnauthorizedUser" element={<Navigate to="/" />} />
									<Route path="/Dashboard" element={<Dashboard />} />
									<Route
										path="/Dashboard/:dashboardId"
										element={
											<Dashboard filterOptions={filterOptions} selectedSort={selectedSort} selectedGrouping={selectedGrouping} onIsAllWidgetsLoaded={handleIsAllWidgetsLoaded} />
										}
									/>
									<Route path="/Reports/:reportName" element={<Report filterOptions={filterOptions} />} />
									<Route path="/Maintenance/:indexName" element={<Maintenance />} />
									<Route path="/Finance/:indexName" element={<Finance filterOptions={filterOptions} />} />
									<Route path="/LogOut" element={<LogOut />} />
									<Route path="/AccessDenied" element={<AccessDenied />} />
									<Route path="*" element={<NotFound />} />
								</Routes>
							</Layout>
						) : (
							isAllowedUser === false && (
								<Routes>
									<Route path="/" element={<UnAuthorized />} />
									<Route path="/LogOut" element={<LogOut />} />
									<Route path="/UnauthorizedUser" element={<UnauthorizedUserDisplay />} />
									<Route path="*" element={<UnAuthorized />} />
								</Routes>
							)
						))}
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<Routes>
						<Route path="/" element={<HandleSignIn />} />
						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				</UnauthenticatedTemplate>
			</Router>
		</>
	);
};

export default App;
