function getCurrentTime() {
    const now = new Date(); // Get the current date and time
    let hours = now.getHours(); // Extract the hours from the current time
    const minutes = now.getMinutes(); // Extract the minutes from the current time

    const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine AM or PM
    hours = hours % 12; // Convert hours to 12-hour format
    hours = hours ? hours : 12; // If hour is 0, set it to 12 (midnight is shown as 12AM)

    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero to minutes if needed

    return `${hours}:${formattedMinutes} ${ampm}`; // Return the formatted time string
}

export default getCurrentTime