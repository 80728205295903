import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Row, Col, Badge } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/MultiSelect.css";

const MultiSelect = ({
	options,
	sources,
	onSourceChange,
	sourcesToSelect,
	onChange,
	selectedOptions,
	singularNoun,
	disabled,
	allOptionsDisabled = false,
	keepOpen = false,
	selectAllCheck = true,
	filterable = true,
	groupingKeys,
	renderOption,
	displayInlineTags = false,
}) => {
	const [showDropdown, setShowDropdown] = useState(keepOpen ? true : false);
	const [filterText, setFilterText] = useState("");
	const [selectedSources, setSelectedSources] = useState(sourcesToSelect ?? []);
	const [collapsedGroups, setCollapsedGroups] = useState({});
	const target = useRef(null);
	const checkboxRefs = useRef({});

	useEffect(() => {
		if (!keepOpen) {
			const handleClickOutside = (event) => {
				if (target.current && !target.current.contains(event.target)) {
					setShowDropdown(false);
				}
			};
			document.addEventListener("mousedown", handleClickOutside);
			return () => document.removeEventListener("mousedown", handleClickOutside);
		}
	}, [keepOpen]);

	useEffect(() => {
		const updateIndeterminateState = () => {
			Object.entries(checkboxRefs.current).forEach(([key, ref]) => {
				if (ref) {
					const ids = ref.dataset.ids.split(",").map(Number);
					ref.indeterminate = getIndeterminateState(ids);
				}
			});
		};
		updateIndeterminateState();
	}, [selectedOptions, options]);

	const toggleGroupCollapse = (key) => {
		setCollapsedGroups((prev) => ({
			...prev,
			[key]: !prev[key],
		}));
	};

	const getDisplayText = () => {
		const count = selectedOptions.length;

		return count > 1 ? `${count} ${singularNoun}s selected` : count === 1 && options.length > 0 ? options.find((option) => option.id === selectedOptions[0]).name : `No ${singularNoun}s selected`;
	};

	const getIndeterminateState = (ids) => {
		const selectableIds = ids.filter((id) => {
			const option = options.find((opt) => opt.id === id);
			return option && !option.disabled;
		});
		const selectedCount = selectableIds.reduce((count, id) => count + (selectedOptions.includes(id) ? 1 : 0), 0);
		return selectedCount > 0 && selectedCount < selectableIds.length;
	};

	const groupOptions = (optionsList, keys) => {
		if (!keys || keys.length === 0) {
			return optionsList; // base case, return the options as an array
		} else {
			const [currentKey, ...restKeys] = keys;
			const grouped = optionsList.reduce((acc, option) => {
				const keyValue = option[currentKey] || `Unknown ${currentKey}`;
				if (!acc[keyValue]) {
					acc[keyValue] = [];
				}
				acc[keyValue].push(option);
				return acc;
			}, {});

			// Recursively group the subgroups
			Object.keys(grouped).forEach((groupKey) => {
				grouped[groupKey] = groupOptions(grouped[groupKey], restKeys);
			});

			return grouped;
		}
	};

	const filterAndGroupOptions = () => {
		let filtered = options.filter((option) => {
			// Check if the option matches the selected sources
			const matchesSource = !sources || selectedSources.length === 0 || selectedSources.includes(option.source);

			// Check if the option matches the filter text
			const matchesText =
				option.name.toLowerCase().includes(filterText.toLowerCase()) ||
				(option.region && option.region.toLowerCase().includes(filterText.toLowerCase())) ||
				(option.territory && option.territory.toLowerCase().includes(filterText.toLowerCase()));

			return matchesSource && matchesText;
		});

		if (!groupingKeys || groupingKeys.length === 0) {
			return filtered; // no grouping
		} else {
			return groupOptions(filtered, groupingKeys);
		}
	};

	const toggleSelectAll = () => {
		// Filter options based on selected sources and item tags
		let filteredOptions = options.filter((option) => {
			// Check if the option matches the selected sources
			const matchesSource = !sources || selectedSources.length === 0 || selectedSources.includes(option.source);
			// Return true if the option matches both criteria
			return matchesSource && !option.disabled;
		});

		// Toggle selection based on whether all filtered options are already selected
		if (selectedOptions.length < filteredOptions.length) {
			// Select all filtered options
			onChange(filteredOptions.map((option) => option.id));
		} else {
			// Deselect all options
			onChange([]);
		}
	};

	const isAllOptionsSelected = () => {
		let sourcedOptions = options.filter((option) => {
			const matchesSource = !sources || (sources && (selectedSources.length === 0 || selectedSources.includes(option.source)));
			return matchesSource && !option.disabled;
		});

		return selectedOptions.length === sourcedOptions.length;
	};

	const isAllSelected = (ids) => {
		const selectableIds = ids.filter((id) => {
			const option = options.find((opt) => opt.id === id);
			return option && !option.disabled;
		});
		return selectableIds.every((id) => selectedOptions.includes(id));
	};

	const handleSelectGroup = (ids, isSelected) => {
		const newSelectedOptions = new Set(selectedOptions);
		ids.forEach((id) => {
			const option = options.find((opt) => opt.id === id);
			if (!option || option.disabled) {
				return;
			}
			if (isSelected) {
				newSelectedOptions.add(id);
			} else {
				newSelectedOptions.delete(id);
			}
		});
		onChange(Array.from(newSelectedOptions));
	};

	const handleSelectOption = (id) => {
		const option = options.find((opt) => opt.id === id);
		if (option && !option.disabled) {
			const newSelectedOptions = [...selectedOptions];
			const index = newSelectedOptions.indexOf(id);
			if (index > -1) {
				newSelectedOptions.splice(index, 1);
			} else {
				newSelectedOptions.push(id);
			}
			onChange(newSelectedOptions);
		}
	};

	const handleSelectSources = (selectedSource) => {
		const newSelectedSources = [...selectedSources];
		const index = newSelectedSources.indexOf(selectedSource);
		if (index > -1) {
			newSelectedSources.splice(index, 1);
		} else {
			newSelectedSources.push(selectedSource);
		}
		setSelectedSources(newSelectedSources);
		onSourceChange(newSelectedSources);
	};

	const renderSources = () => {
		return (
			<Row className="px-4">
				{sources.sort((a, b) => a.localeCompare(b)).map((source) => {
					return (
						<Col key={"source-" + source} xs={{ span: 4 }}>
							<Form.Check
								key={"source_" + source}
								id={"source_" + source}
								type="checkbox"
								label={source}
								checked={selectedSources.includes(source)}
								onChange={() => handleSelectSources(source)}
								className="dropdown-source"
							/>
						</Col>
					);
				})}
			</Row>
		);
	};

	const getGroupIds = (groupedOptions) => {
		if (Array.isArray(groupedOptions)) {
			return groupedOptions.map((option) => option.id);
		} else {
			return Object.values(groupedOptions).flatMap((subGroupOptions) => getGroupIds(subGroupOptions));
		}
	};

	const renderGroupedOptions = (groupedOptions, level = 0, parentKeys = []) => {
		if (Array.isArray(groupedOptions)) {
			// Base case, render the options
			return groupedOptions.map((option) => (
				<Form.Check
					key={`${singularNoun}_${option.id}`}
					id={`${singularNoun}_${option.id}`}
					type="checkbox"
					label={
						<span className={option.disabled ? "disabled-item" : ""}>
							{renderOption ? renderOption(option) : option.name}
							{option.tags && displayInlineTags && option.tags.length > 0 && (
								<span style={{ marginLeft: "5px" }}>
									{option.tags.map((tag) => (
										<Badge key={`${option.id}-tag-${tag.tagName}`} bg="" style={{ ...tag.tagStyle, marginRight: "2px" }}>
											{tag.tagName}
										</Badge>
									))}
								</span>
							)}
						</span>
					}
					checked={selectedOptions.includes(option.id)}
					onChange={() => handleSelectOption(option.id)}
					className="dropdown-item"
					style={{ marginLeft: (level + 1) * 20 + "px" }}
				/>
			));
		} else {
			// groupedOptions is an object
			return Object.entries(groupedOptions).map(([groupKey, subGroupOptions]) => {
				const currentKeys = [...parentKeys, groupKey];
				const groupId = currentKeys.join("-");
				const groupIds = getGroupIds(subGroupOptions);
				const isChecked = isAllSelected(groupIds);
				const isIndeterminate = getIndeterminateState(groupIds);

				return (
					<div key={groupId}>
						<Form.Check
							type="checkbox"
							key={`${singularNoun}_${level}_${groupKey}`}
							id={`${singularNoun}_${level}_${groupKey}`}
							label={
								<span>
									<FontAwesomeIcon
										icon={"triangle"}
										rotation={collapsedGroups[groupId] ? 90 : 180}
										size="2xs"
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											toggleGroupCollapse(groupId);
										}}
										style={{ cursor: "pointer", marginRight: "5px" }}
									/>{" "}
									{groupKey}
								</span>
							}
							disabled={allOptionsDisabled}
							checked={isChecked}
							onChange={(e) => handleSelectGroup(groupIds, e.target.checked)}
							className="dropdown-item"
							style={{ fontWeight: "bold", marginLeft: (level + 1) * 20 + "px" }}
							ref={(el) => {
								if (el) {
									el.dataset.ids = groupIds.join(",");
									checkboxRefs.current[groupId] = el;
								}
							}}
						/>
						{!collapsedGroups[groupId] && renderGroupedOptions(subGroupOptions, level + 1, currentKeys)}
					</div>
				);
			});
		}
	};

	return (
		<div ref={target} style={{ position: "relative", width: "100%" }}>
			{!keepOpen && (
				<Button variant={"outline-secondary-white-background"} onClick={() => setShowDropdown(!showDropdown)} className="btn w-100 dropdown-toggle text-start smallerFont" disabled={disabled}>
					{getDisplayText()}
				</Button>
			)}
			{(showDropdown || keepOpen) && (
				<Form className={keepOpen ? "multi-select-options" : "dropdown-menu show"} style={{ minWidth: "100%" }}>
					{(selectAllCheck || filterable) && (
						<>
							<Row className="align-items-center px-2">
								{selectAllCheck && (
									<Col xs="auto" className="pe-2">
										<Form.Check type="checkbox" checked={isAllOptionsSelected()} onChange={toggleSelectAll} className="select-all-checkbox" />
									</Col>
								)}
								{filterable && (
									<Col>
										<Form.Control
											type="text"
											className="dropdown-filter-box"
											onChange={(e) => setFilterText(e.target.value)}
											value={filterText}
											placeholder="Type to Search..."
											autoFocus
										/>
									</Col>
								)}
							</Row>
							<hr />
						</>
					)}

					{sources && (
						<>
							<div className="sources-listing">{renderSources()}</div>
							<hr />
						</>
					)}

					<div className={"options-dropdown " + (keepOpen ? "keepOpen" : "full")}>{renderGroupedOptions(filterAndGroupOptions())}</div>
				</Form>
			)}
		</div>
	);
};

MultiSelect.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			source: PropTypes.string,
			name: PropTypes.string.isRequired,
			tags: PropTypes.arrayOf(
				PropTypes.shape({
					tagName: PropTypes.string.isRequired,
					tagStyle: PropTypes.object.isRequired,
				})
			),
		})
	).isRequired,
	sources: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func.isRequired,
	selectedOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
	singularNoun: PropTypes.string.isRequired,
	groupingKeys: PropTypes.arrayOf(PropTypes.string),
	renderOption: PropTypes.func,
	displayInlineTags: PropTypes.bool,
	keepOpen: PropTypes.bool,
	selectAllCheck: PropTypes.bool,
};

export default MultiSelect;
