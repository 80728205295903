import React from "react";
import "./Loader.css";

const Loader = ({ message }) => {
  return (
    <>
      <div className="overlay show"></div>
      <div className="spinner show">
        <div className="loader"></div>
        <p>{message}</p>
      </div>
    </>
  );
};
export default Loader;
