import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

export const LogOut = () => {
    const { instance } = useMsal();
    const [isInteractionInProgress, setIsInteractionInProgress] = useState(false);

    useEffect(() => {
        if (!isInteractionInProgress) {
            localStorage.removeItem("amplifi_user_data");
            localStorage.removeItem("amplifi_loginTime");
            localStorage.clear();
            sessionStorage.clear();

            setIsInteractionInProgress(true);

            instance
                .logoutPopup({
                    postLogoutRedirectUri: "/",
                    mainWindowRedirectUri: "/",
                })
                .catch((error) => {
                    console.error("Logout error: ", error);
                })
                .finally(() => {
                    setIsInteractionInProgress(false);
                });
        }
    }, [isInteractionInProgress, instance]);
};
